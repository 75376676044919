import { OutputData } from '@editorjs/editorjs'
import { userFeatureFlags } from '../constants'

// common types used on server and frontend
export enum DESIA_EVENT {
  SEARCH_QUERY_V2_REQUEST = 'search:query_v2_request',
  SEARCH_QUERY_V2_RESPONSE = 'search:query_v2_response',
  CHAT_ASK = 'chat:ask',
  CHAT_HISTORY = 'chat:history',
  CHAT_LIST = 'chat:list',
  CHAT_DETAILS = 'chat:details',
  CHAT_DELETE = 'chat:delete',
  DOCUMENT_UPLOAD = 'document:upload',
  DOCUMENT_DELETE = 'document:delete',
  DOCUMENT_LIST = 'document:list',
  DOCUMENT_STATUS_UPDATE = 'document:status',
  FACT_CHECK_REQUEST = 'docgen:fact_check_request',
  FACT_CHECK_RESPONSE = 'docgen:fact_check_response',
  FACT_CHECK_V2_REQUEST = 'docgen:fact_check_v2_request',
  FACT_CHECK_V2_RESPONSE = 'docgen:fact_check_v2_response',
  RESEARCH_REQUEST = 'docgen:research_request',
  RESEARCH_RESPONSE = 'docgen:research_response',
  DOCGEN_ASK_REQUEST = 'docgen:ask_request',
  DOCGEN_ASK_RESPONSE = 'docgen:ask_response',
  DOCGEN_CREATE_REPORT_REQUEST = 'docgen:create_report_request',
  DOCGEN_CREATE_REPORT_RESPONSE = 'docgen:create_report_response',
  DOCGEN_LIST_REPORTS_REQUEST = 'docgen:list_reports_request',
  DOCGEN_LIST_REPORTS_RESPONSE = 'docgen:list_reports_response',
  DOCGEN_GET_REPORT_REQUEST = 'docgen:get_report_request',
  DOCGEN_GET_REPORT_RESPONSE = 'docgen:get_report_response',
  DOCGEN_UPDATE_REPORT_REQUEST = 'docgen:update_report_request',
  DOCGEN_UPDATE_REPORT_RESPONSE = 'docgen:update_report_response',
  DOCGEN_DELETE_REPORT_REQUEST = 'docgen:delete_report_request',
  DOCGEN_DELETE_REPORT_RESPONSE = 'docgen:delete_report_response',
  DOCGEN_EXTRACT_ENTITIES_REQUEST = 'docgen:extract_entities_request',
  DOCGEN_EXTRACT_ENTITIES_RESPONSE = 'docgen:extract_entities_response',
}

export type ResAppUser = {
  sid: string
  user_roles: Array<string>
  app_metadata: Record<string, any>
  user_metadata: Record<string, any>
  nickname: string
  name: string
  picture: string
  updated_at: string
  email: string
  email_verified: boolean
  sub: string
}

export type AssistantAskMode = 'simple' | 'expert'
export type AssistantAskType = 'ASK_THREAD' | 'DOC_GEN'
export type RequestAssistantAsk = {
  message: string
  conversationType?: AssistantAskType
  conversationId?: string
  model?: string
  temperature?: number
  systemPrompt?: string
  maxTokens?: number
  seed?: number
  connectorsV2?: SourceConnector[]
  webSearchModel?: string
  mode?: AssistantAskMode // todo: make mandatory once frontend adopts new api
  context?: string
  tools?: { name: string }[]
  followUpQuestions?: number
  forceSingleStep?: boolean
  regeneration?: boolean
  documents?: SourceDocument[]
  focusedAnalysis?: boolean
  fileIDs?: string[]
}

export type SourceDocumentMetadata = {
  // internal doc metadata
  document_created_at_desia?: string
  document_created_at_source?: any
  document_is_part_of_desia_library?: boolean
  document_source?: string
  document_source_details?: Record<string, any>
  document_type_friendly?: string
  document_updated_at_desia?: string
  document_updated_at_source?: any
  external_link?: string
  internal_link?: string
  label?: string
  ranking_score?: number
  source?: any
  document_source_path?: string
  document_visibility?: string

  // outlook & teams metadata
  attachment_count?: number | null
  body?: string
  document_created_at_datetime?: string
  document_received_at_datetime?: string
  document_sent_at_datetime?: string
  receiver?:
    | {
        email_address?: string
        name?: string
      }[]
    | {
        email_address?: string
        name?: string | null
      }
  resource_attachments?:
    | {
        id: string
        last_modified_date?: string | null
        name: string
        size?: number | null
      }[]
    | null
  sender?: {
    email_address?: string
    name?: string
  }
  summary?: string
}
export type SourceDocument = {
  document_id: string
  doc_metadata?: SourceDocumentMetadata
  title: string
  url?: string
  text: string
  tool_name?: string | null
  output_file?: { b64_data?: string; filename?: string } | null
}

export type ResChatQuery = {
  socketId: string
  conversationId: string
  timestamp: string
  status: 'error' | 'processing' | 'done'
  sources: {
    internal: {
      documents: SourceDocument[]
    }
    web: {
      documents: SourceDocument[]
    }
    thirdParty: {
      documents: SourceDocument[]
    }
  }
  question: string
  text: string
  conflict: string
  relatedQuestions: string[]
}

export type ResponseDocument = {
  document_id: string
  created_by_oauth_user_id: string
  oauth_organization_id: string
  document_name: string
  document_type: string
  document_type_friendly: string
  document_size: string
  document_size_bytes: number
  document_source: string
  document_created_at: string
  document_author: string
  document_other_info: Record<string, any>
  document_storage_location_path: string
  document_secure_shared_link: string
  document_processing_status: string
  document_is_ready_to_use: boolean
  document_is_part_of_desia_library: boolean
  created_at_desia: string
  updated_at_desia: string
  document_storage_class: string
  document_source_details?: Record<string, any>
  document_updated_at?: string
  document_visibility?: string
  document_source_desia_dict_id?: number
  document_source_path_treeview?: ResourceFileStructure[]
  document_source_path?: string
}

export type ResourceFileStructure = {
  internal_element_id: number
  element_type: string
  element_name: string
  parent_element_internal_ids: number[]
  children_element_internal_ids: number[] | null
}

export type Citation = {
  text: string
  start: number
  end: number
  document_ids: string[]
  highlights?: {
    file_id: string
    highlight: string
    score?: number
    ranking_score?: number
    retrieval_score?: number
    page_number?: number | null
  }[]
  chart_table_id?: string | null
  index_x?: number | null
  index_y?: number | null
  citation_uuid?: number | null
}

type ResponseChatStreamBody = {
  text: string
  citations: Citation[]
  documents: Array<{
    text: string
    document_id: string
    doc_metadata?: SourceDocumentMetadata
    title: string
    url: string
  }>
  search_results: Array<any>
  search_queries: Array<any>
  finish_reason: string
  questions?: string[]
}

export type StreamEventType =
  | 'stream-start'
  | 'search-queries-generation'
  | 'search-results'
  | 'text-generation'
  | 'citation-generation'
  | 'stream-end'
  | 'followup-question'

export type ResponseChatStream = {
  is_finished: boolean
  conversation_id: string
  metadata: {}
  body: Partial<ResponseChatStreamBody>
  event_type: StreamEventType
  requestId?: string
  timestamp?: number
  reportId?: string
}

export type ResponseChatList = {
  user_id: 'string'
  id: 'string'
  created_at: 'string'
  updated_at: 'string'
  title: 'string'
  dossier_id: string
}

export type MessageAgentUser = {
  type: string
  text: string
  id: string
  created_at: string
  updated_at: string
  generation_id: any
  position: number
  is_active: boolean
  documents: Array<any>
  citations: Array<any>
  agent: 'USER'
}

export type MessageAgentChatBot = {
  type: string
  text: string
  id: string
  created_at: string
  updated_at: string
  generation_id: any
  position: number
  is_active: boolean
  documents: Array<{
    text: string
    document_id: string
    document_link?: string
    title: string
    url: string
  }>
  citations: Array<{
    text: string
    start: number
    end: number
    document_ids: Array<string>
  }>
  agent: 'CHATBOT'
}

export type ResponseChatMessage = MessageAgentUser | MessageAgentChatBot

export type ResponseChatDetails = {
  id: string
  created_at: string
  description: string
  title: string
  updated_at: string
  user_id: string
  messages: ResponseChatMessage[]
  plans?: ExpertModePlan[]
  follow_up_questions: FollowUpQuestion[]
  dossier_id?: string
}

export type WebSocketRequestWrapper<T> = {
  requestId: string
  timestamp: number
  params: T
}

export type WebSocketResponseWrapper<T> = {
  requestId: string
  error: string | null
  data: T | null
  loading: false
  timestamp: number
}

export type ResponseSearchQuery = {
  id: string
  retrieval_rank: number
  ranking_rank: number
  ranking_score: number
  retrieval_score: number
  text: string
  highlight: {
    passage_text?: string[]
    'highlight-3'?: string | null
    'highlight-2'?: string | null
    'highlight-1'?: string | null
  } | null
  title: string
  url: string
  document_type_friendly: string
  document_updated_at_desia: string
  document_created_at_desia: string
  document_is_part_of_desia_library: boolean
  document_link?: string
  document_source?: string
  document_source_path?: string
  page_number?: number
}[]

export type SourceConnector =
  | { id: 'web-search' }
  | { id: 'internal-search'; include?: { title: string; id: string }[] }
  | { id: 'teams-search' }
  | { id: 'outlook-search' }

type ExpertModeStep = {
  description: string
  next_tools: string[]
  answer: string | null
  failed_tools: string[]
  doc_ref: string | null
}

export type ExpertModeDocument = {
  id: string
  snippet: string
  timestamp: string
  title: string
  url: string
  doc_metadata?: SourceDocumentMetadata
}

export type ExpertModeUMessageUser = {
  content: string
  additional_kwargs: {}
  response_metadata: {}
  name: null
  id: string
  example: boolean
  type: 'human'
}
// todo: type fully if needed
type ExpertModeMessageAI = {
  type: 'ai'
}
type ExpertModeMessageTool = {
  type: 'tool'
}
export type ExpertModeMessage =
  | ExpertModeUMessageUser
  | ExpertModeMessageAI
  | ExpertModeMessageTool
type ExpertModePlan = {
  id: string
  conversation_id: string
  steps: ExpertModeStep[]
}
export type ResponseExpertModeStream = {
  is_finished: boolean
  conversation_id: string
  metadata: {}
  body: {
    messages: ExpertModeMessage[]
    question: string
    plan: ExpertModePlan
    documents: Record<string, ExpertModeDocument[]> | null
    final_message: string | null
    is_complete: boolean | null
  }
  event_type: 'agent-response'
}

export type ResponseAssistantStream =
  | ResponseChatStream
  | ResponseExpertModeStream

// frontend specific types
export enum QueryStatus {
  INITIALISED = 'initialised',
  IDLE = 'idle',
  FETCHING = 'fetching',
  ERROR_FETCHING = 'error_fetching',
  UPLOADING = 'uploading',
  ERROR_UPLOADING = 'error_uploading',
  SUCCEEDED = 'succeeded',
}

export type WebSocketRequest = {
  requestId: string
  params: Record<string, unknown>
  timestamp: number
  conversationId?: string
  userId?: string
  query?: string
}

export type WebSocketResponse = {
  requestId: string
  error: string | null
  data: Record<string, any>
  loading: false
  timestamp: number
}

export interface QueryState {
  event: string
  request: WebSocketRequest
  error: string | null
  data: Record<string, unknown> | null
  loading: boolean
  timestamps: {
    request: number | null
    response: number | null
  }
}

export type SearchQueryExtract = {
  text: string
  highlight: {
    passage_text?: string[]
    'highlight-3'?: string | null
    'highlight-2'?: string | null
    'highlight-1'?: string | null
  } | null
  page_number?: number | null
}

export type DedupedSearchQueryItem = {
  id: string
  retrieval_rank: number
  ranking_rank: number
  ranking_score: number
  retrieval_score: number
  text: string
  highlight: {
    passage_text?: string[]
    'highlight-3'?: string | null
    'highlight-2'?: string | null
    'highlight-1'?: string | null
  } | null
  title: string
  url: string
  document_type_friendly: string
  document_created_at_desia: string
  document_updated_at_desia: string
  document_is_part_of_desia_library: boolean
  document_link?: string
  document_source?: string
  document_source_details?: Record<string, any>
  document_source_path?: string
  doc_metadata?: SourceDocumentMetadata
  extracts: SearchQueryExtract[]
}
export type DedupedSearchQuery = DedupedSearchQueryItem[]
export type DocumentPreviewResource = {
  extracts: SearchQueryExtract[]
  document_type_friendly: string
  id: string
  title: string
  text: string
  url: string
  document_link?: string
  doc_metadata?: SourceDocumentMetadata
}

type FollowUpQuestion = string

export type UserMessage = {
  query: string
  requestId?: string
  conversationId?: string
  attachments?: File[]
  timestamp: number
  mode?: AssistantAskMode // fixme
  role: 'user'
}

export type SystemMessage = {
  conversationId: string
  requestId?: string
  timestamp: number
  role: 'system'
  data: {
    plan?: ExpertModePlan
    planDocuments?: Record<string, ExpertModeDocument[]>
    citations?: Citation[]
    documents?: SourceDocument[]
    followUpQuestions?: FollowUpQuestion[]
    text?: string
    conflictingInformation?: string
    status?: string
    isFinished?: boolean
  }
}

export type CitedBlock = {
  text: string,
  citations?: Citation[]
  documents?: SourceDocument[]
}

export type ExtendableCitedBlock = CitedBlock & {
  [key: string]: any
}

export type ChatMessage = UserMessage | SystemMessage

export type Conversation = ChatMessage[]

export type ChatSummary = {
  conversationId: string
  query: string
  created_at: number
  updated_at: number
  requestId?: string
  dossierId?: string
}
export type ConversationId = string

export type UserFeatureFlag = (typeof userFeatureFlags)[number]

export type UserSettings = {
  settings: {
    assistant: {
      sources: {
        ask: SourceSettings
        dossier: SourceSettings
        report: SourceSettings
      }
      parameters?: {
        model?: string
        temperature?: number
        systemPrompt?: string
        seed?: number
      }
      mode: AssistantAskMode
    }
  }
}

export type SourceSettings = {
  internalSearch: boolean
  webSearch: boolean
  teamsSearch: boolean
  outlookSearch: boolean
  focusedAnalysis: boolean
  files: { title: string; id: string }[]
}

export type NewAsk = {
  requestId: string
  question: string
  timestamp: number
  mode: AssistantAskMode
}

export type FollowUpAsk = {
  conversationId: string
  requestId: string
  question: string
  timestamp: number
  mode: AssistantAskMode
}

export type ResponseDocGenReport = {
  id: string
  title: string
  content: string
  user_id: string
  organization_id: string
  created_at: string
  updated_at: string
  dossier_id?: string
}

export type RequestDocGenGetReport = {
  report_id: string
}

export type RequestDocGenUpdateReport = {
  report_id: string
  title: string
  content: string
}

export type RequestDocGenDeleteReport = {
  report_id: string
}

export type ResponseDocGenDeleteReport = {
  report_id: string
}

export type APIResponse<T> = {
  error: string | null
  loading: boolean
  data: T | null
}

export type EditReport = {
  server: APIResponse<ResponseDocGenReport>
  client: {
    title: string
    extractedEntities?: ResponseEntityExtraction
    report: OutputData
  }
  lastSave: {
    requestId: string
    loading: boolean
    error: string | null
    timestamp: number
  } | null
  entityExtraction: {
    status: ASYNC_STATUS
  }
}

export type ResponseSignedUrlsUpload = {
  signed_urls: {
    file_name: string
    signed_url: string
    headers: {
      [key: string]: string
    }
    success: boolean
    exists: boolean
    details: any | null
  }[]
}

// see https://stackoverflow.com/a/51399781/2438257
export type ArrayElement<ArrayType extends readonly unknown[]> =
  ArrayType extends readonly (infer ElementType)[] ? ElementType : never

export type ResponseSignedUrlUpload = ArrayElement<
  ResponseSignedUrlsUpload['signed_urls']
>

export enum ASYNC_STATUS {
  'idle' = 'idle',
  'loading' = 'loading',
  'success' = 'success',
  'error' = 'error',
}

export type RequestEntityExtraction = {
  reportId: string
  content: string
}

export type ExtractedEntity = {
  entity: string
  type: string
  isMainEntity: boolean
  accuracy: number
}

export type ResponseEntityExtraction = {
  reportId: string
  entities: ExtractedEntity[]
  extractionDateTime: string
}

export type IntegrationStage = 'authentication' | 'file-selection'

export type FileStructure = {
  internal_element_id: number
  document_id?: string
  element_type: string
  element_name: string
  element_path?: string
  parent_element_internal_ids: number[]
  children_element_internal_ids: number[] | null
  is_included: boolean
  child_is_included: boolean
  is_excluded: boolean
  child_is_excluded: boolean
}

export type Integration = {
  integration_id: number
  integration_code_name: string
  integration_name: string
  integration_description: string
  integration_is_enabled: boolean
  integration_has_setup: boolean
  integration_is_process: boolean
  integration_created_at: string
  integration_updated_at: string | null
}

export type IntegrationStatus = {
  integration_details: Integration
  synchronization_details: IntegrationSyncDetail
  cancel_undo_changes_available: boolean
}

export type IntegrationSyncDetail = {
  latest_integration_pipeline_events: IntegrationPipelineEvent[]
  last_7days_unresolved_failures: IntegrationFailure[]
  files_to_synchronize_count: number
  available_count: number
  failed_count: number
  uploading_count: number
  normalizing_count: number
  chunking_count: number
  embedding_count: number
}

export type IntegrationPipelineEvent = {
  status: string
  created_at: string
  updated_at: string
  pipeline_step: string
  status_details: string | null
  integration_element_type: string
}

export type IntegrationFailure = {
  normalization_status: string
  normalization_details: string
  element_name_at_source: string
  element_path_at_source: string
  element_state_at_source: string
  last_processed_at_desia: string
  integration_upload_status: string
  integration_upload_details: string | null
  element_is_deleted_at_source: boolean
  element_last_modified_at_source: string
}

export type RequestIntegrationCredential = {
  integration_id: number
  tenant_id: string
  client_id: string
  client_secret: string
}

export type IntegrationCredential = {
  tenant_id: string
  client_id: string
  client_secret: string
}

export type ResponseIntegrationStructure = {
  integration_details: Integration
  structures: FileStructure[]
}

export type SynchronizationControlAction = 'include' | 'exclude'

export type RequestSynchronizationControl = {
  auth: {
    user_id: string
    organization_id: string
    user_email: string
  }
  integration_id: number
  payload: {
    action: SynchronizationControlAction
    internal_element_ids: number[]
  }[]
  resync_all: boolean
}

export type RequestSynchronizationControlDelete = {
  integration_id: number
  internal_elements_ids: number[]
}

export type FileResource = {
  resource_name: string
  resource_visibility: ResourceVisibility
  resource_overwrite: boolean
  resource_shared_with_user_ids: string[]
}

export type ResourceVisibility =
  | 'private'
  | 'organization'
  | 'public'
  | 'shared'

export type RequestChatDelete = {
  conversationId: string
}

export type Dossier = {
  id: string
  subject: string
  description: string
  status: string
  icon: string
  conversations: number
  reports: number
  updated_at: string
  created_at: string
  created_by: string
}

export type DossierTab = 'content' | 'news' | 'files'

export type HasDateProperty<T> = {
  [K in keyof T]: T[K] extends string ? K : never
}[keyof T]

export type RequestCreateDossier = {
  subject: string
  description: string | null
  icon: string
}

export type RequestAddDossierThread = {
  dossierId: string
  threadId: string
}

export type RequestAddDossierReport = {
  dossierId: string
  reportId: string
}

export type DossierDetail = {
  id: string
  subject: string
  description: string | null
  status: string
  created_by: string
  created_at: string
  updated_at: string
  icon: string
  conversations: DossierConversation[]
  reports: ResponseDocGenReport[]
  sources: DossierSource[]
}

export type DossierConversation = {
  user_id: string
  title: string
  description: string
  id: string
  created_at: string
  updated_at: string
  type: string
  follow_up_questions: string[]
  dossier_id?: string
}

export type RequestAddDossierSource = {
  dossierId: string
  sources: RequestDossierSource[]
}

export type RequestDossierSource = {
  source_dict_id: number
  resource_id: string
}

export type DossierSource = {
  id: number
  dossier_id: string
  source_dict_id: number
  resource_id: string
  created_at: string
  updated_at: string | null
}

export type RequestDeleteDossierSource = {
  dossierId: string
  sourceId: number
}

export type ResponseChartData = {
  data: { label: string; value: number[] }[]
  label: string
  values: string[]
  chartType: string
  title: string
}

export type RequestCancelIntegrationSync = {
  integration_id: number
}

type RequestId = string
type MessageId = ConversationId | RequestId
export type SourceDocumentId = string
export type SelectedCitations = Record<MessageId, SourceDocumentId[]>

export type AssistantState = {
  hoverCitations?: SelectedCitations
  streamStatus: StreamStatus
}

export type AssistantStore = {
  newAsk: NewAsk[]
  liveConversations: {
    requestIds?: string[]
    conversationId?: string
    conversation: Conversation
  }[]
  list: ChatSummary[]
  state: AssistantState
  stopConversationStatus: QueryStatus
}

export type DataStreamedResponse = ResponseAssistantStream & {
  requestId: string
  timestamp: number
}

export enum StreamStatus {
  Ready = 'Ready',
  Searching = 'Searching',
  SearchingWeb = 'Searching web',
  SearchingInternalDocs = 'Searching internal docs',
  SearchingWebAndInternalDocs = 'Searching web and internal docs',
  ApplyingCitations = 'Applying citations',
  Answering = 'Answering',
  Unexpected = '...',
}

export type SourceType = 'ask' | 'dossier' | 'report'

export type TemplateSection = {
  id: number
  title: string
  description: string
  expanded: boolean
  subsections?: TemplateSubsection[]
}

export type TemplateSubsection = {
  id: number
  title: string
  subsections?: TemplateSubsection[]
}

export type Section = {
  id?: string
  title: string
  name: string
  description?: string | null
  position?: number | null
  parent_id?: string | null
  subsections: Section[]
}

export type DocgenTemplateRequest = {
  name: string
  title: string
  description?: string | null
  writing_style: string
  position?: number | null
  sections?: Section[]
}

export type DocgenTemplate = {
  id: string
  name: string
  title: string
  description?: string | null
  visiblity?: string
  created_by?: string
  writing_style: string
  position?: number | null
  template_type?: string
  created_at?: string | null
  updated_at?: string | null
  sections?: Section[]
}

export type DocgenSession = {
  id: string
  topic: string
  visibility?: 'private' | 'organization' | 'shared' | 'public'
  include_all_files?: boolean
  template_id: string
  title: string
  files: string[]
  theme?: DocgenTheme
  status: DocgenSessionStatus | null
  content?: {
    knowledge_base: {
      citations: {
        [id: string]: DocgenCitation
      }
      tree: DocgenContentSection
    }
    report: string
    experts: {
      [key: string]: string
    }
  }
}

export type DocgenThemeSection = {
  name: string
  title: string
  description: string[]
  theme_id?: string
  updated_at?: string
  created_at?: string
}

export type DocgenTheme = {
  id?: string
  topic: string
  name: string
  title: string
  template_id: string
  sections: DocgenThemeSection[]
  status: DocgenThemeStatus
  output?: string
}

export enum DocgenThemeStatus {
  CREATED = 'created',
  CREATING = 'creating',
  ERROR = 'error',
}

export enum DocgenSessionStatus {
  CREATED = 'created',
  CREATING = 'creating',
  ERROR = 'error', // deprecated
  THEME_GENERATED = 'theme_generated',
  THEME_GENERATING = 'generating_theme',
  DRAFT_ERROR = 'draft_error',
  THEME_ERROR = 'theme_error',
}

export type DocgenCitation = {
  document_id: string
  context: string
  snippets: string[]
  title: string
  meta?: {
    query?: string
    question?: string
    placement?: string
  }
  citation_uuid: number
  page_number?: number | null
  url?: string
}

export type DocgenContentSection = {
  children: DocgenContentSection[]
  content: number[]
  name: string
  need_regenerate_synthesize_output: boolean
  parent: string | null
  synthesize_output: string | null
  themes?: DocgenContentTheme[]
}

export type DocgenContentTheme = {
  discussions: DocgenDiscussion[]
  question: string
}

export type DocgenDiscussion = {
  cited_info: {
    [index: number]: DocgenCitation
  }
  claim_to_make: string
  queries: string[]
  role: string
  role_description: string
  utterance: string
  utterance_type: string
}

export enum DocgenStep {
  TEMPLATE = 'Template',
  TOPIC = 'Topic & Sources',
  OUTLINE = 'Outline',
  DRAFT = 'Draft',
}

export type RequestIntegrationGreenlistedUpsert = {
  integration_id: number
  element_raw_ids: string[]
}

export type IntegrationGreenlistedStructure = {
  greenlisted_id: number | null
  element_raw_id: string
  element_type: string | null
  element_path: string | null
  is_valid: boolean
}

export type RequestIntegrationGreenlistedApprove = {
  integration_id: number
  greenlisted_ids: number[]
}

export type RequestIntegrationGreenlistedDelete = {
  integration_id: number
  greenlisted_ids: number[]
}

export enum IntegrationCode {
  ONEDRIVE = 'microsoft-onedrive',
  SHAREPOINT = 'microsoft-sharepoint',
  TEAMS = 'microsoft-teams',
  OUTLOOK = 'microsoft-outlook',
}

export type ResponseUserIntegrations = {
  oauth_user_id: string
  integrations: UserIntegration[]
}

export type UserIntegration = {
  integration_id: number
  integration_name: string
  integration_code_name: string
  microsoft_integration: boolean
  user_level_integration: boolean
  integration_enabled_by_user: boolean
  organization_level_integration: boolean
  integration_enabled_in_organization: boolean
}

export type ResponseGenerateAuthorizationURL = {
  client_id: string
  redirect_uri: string
  response_mode: string
  response_type: string
  scope: string
  state: string
  tenant_id: string
  uri: string
}

export enum SettingsTab {
  PROFILE = 'profile',
  APPS = 'connected apps',
  SETTINGS = 'developer settings',
}

export enum DocumentPreviewType {
  SEARCH = 'search',
  ASK = 'ask',
  REPORT = 'report',
  DOCGEN = 'docgen',
}

export type SearchConnectors = {
  internalSearch: boolean
  teamsSearch: boolean
  outlookSearch: boolean
}
