import {
  ChevronLeft,
  ChevronRight,
  Paperclip,
  X,
  ZoomIn,
  ZoomOut,
} from 'lucide-react'
import { Button } from '../ui/button'
import { Document, Page } from 'react-pdf'
import ExternalLink from '@/assets/ExternalLink'
import {
  memo,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from 'react'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import {
  DocumentPreviewResource,
  DocumentPreviewType,
  SourceDocument,
} from '@/types/types'
import {
  getFileId,
  handleOpenLink,
  replaceMicrosoftHighlight,
  shortString,
} from '@/utils/utils'
import { PageCallback } from 'react-pdf/dist/esm/shared/types.js'
import { CustomAlert } from '../CustomAlert'
import {
  getTextItemWithNeighbors,
  highlightPartialText,
} from './highlightPartialText'
import { handleError } from '@/utils/handleError'
import { Mutex } from 'async-mutex'
import { reactPDFOptions } from '@/constants'

import 'react-pdf/dist/Page/AnnotationLayer.css'
import 'react-pdf/dist/Page/TextLayer.css'
import { PDFWorker } from './PDFWorker'
import Divider from '../ui/divider'
import { SourceContent, SourceHeader, SourceIcon } from '../Source'
import { useDebounce } from '@/hooks/useDebounce'
import DOMPurify from 'dompurify'
import { getFileIcon } from '@/utils/components'
import Markdown from 'markdown-to-jsx'
import { useDispatch } from 'react-redux'
import { AppDispatch } from '@/store/store'
import documentApi from '../Document/documentApi'

const DocumentPage = memo(
  ({
    pageNumber,
    scale,
    initialWidth,
    highlight,
    pageLoadLock,
    onLoadSuccess,
  }: {
    pageNumber: number
    scale: number
    initialWidth: number
    highlight:
      | {
          highlight: string
          page_number?: number | null
        }
      | undefined
    pageLoadLock: Mutex
    onLoadSuccess: (pageNumber: number) => void
  }) => {
    const [textItems, setTextItems] = useState<any>()
    const [readyToLoadTextLayer, setReadyToLoadTextLayer] =
      useState<boolean>(false)
    const releaseRef = useRef<(() => void) | null>(null)

    // fixes span overlaps, based on this issue: https://github.com/wojtekmaj/react-pdf/issues/1848
    useEffect(() => {
      setReadyToLoadTextLayer(false)

      const acquireLock = async () => {
        const releaseLock = await pageLoadLock.acquire()

        releaseRef.current = () => {
          releaseLock()
        }

        setReadyToLoadTextLayer(true)
      }

      acquireLock()

      return () => {
        if (releaseRef.current) {
          releaseRef.current()
        }
      }
    }, [pageNumber, pageLoadLock])

    const handleTextLayerLoad = () => {
      if (releaseRef.current) {
        releaseRef.current()
        releaseRef.current = null
      }
    }

    const textRenderer = useCallback(
      (textItem: any) => {
        if (!textItems) {
          return ''
        }

        const { itemIndex, str } = textItem

        if (!highlight) return str

        const highlightPageNumber = highlight.page_number

        if (highlightPageNumber) {
          if (highlightPageNumber !== pageNumber) {
            return str
          }
        }

        const stringsToHighlight = highlight.highlight
          .split('\\n')
          .filter((v) => v)

        const splitHighlights = stringsToHighlight.flatMap((string) => {
          const splitString = string.split(' ')
          const maxWords = 10
          if (splitString.length > maxWords) {
            const half = Math.round(splitString.length / 2)

            return [
              splitString.slice(0, half).join(' '),
              splitString.slice(half).join(' '),
            ]
          }
          return [string]
        })

        const neighbourText = getTextItemWithNeighbors(textItems, itemIndex)
        const text = highlightPartialText(str, neighbourText, splitHighlights)

        return text
      },
      [textItems, highlight, pageNumber]
    )

    const onPageLoadSuccess = useCallback(
      async (page: PageCallback) => {
        try {
          const textItems = await page.getTextContent()
          setTextItems(textItems.items)
          onLoadSuccess(pageNumber)
        } catch (e) {
          handleError(e)
        }
      },
      [pageNumber, onLoadSuccess]
    )

    return (
      <Page
        customTextRenderer={textRenderer}
        pageNumber={pageNumber}
        width={initialWidth}
        scale={scale}
        onLoadSuccess={onPageLoadSuccess}
        className="my-6"
        renderTextLayer={readyToLoadTextLayer}
        onRenderTextLayerSuccess={handleTextLayerLoad}
        renderAnnotationLayer={false}
        loading={<></>}
      />
    )
  }
)

export const DocumentPreview = memo(
  ({
    resource,
    type,
    initialWidth,
    selectedExtractIndex,
    setSelectedExtractIndex,
  }: {
    resource: DocumentPreviewResource
    type: DocumentPreviewType
    initialWidth: number
    selectedExtractIndex: number
    setSelectedExtractIndex: (index: number) => void
  }) => {
    const [numPages, setNumPages] = useState(0)
    const [selectedResourceLink, setSelectedResourceLink] = useState<
      string | null
    >(null)
    const [scale, setScale] = useState(1)
    const [error, setError] = useState<
      'generic' | 'source' | 'unsupported' | null
    >(null)
    const [loading, setLoading] = useState(false)
    const [loadedPages, setLoadedPages] = useState<number[]>([])
    const [documentLoaded, setDocumentLoaded] = useState(false)
    const [initialJump, setInitialJump] = useState(true)

    const debouncedScale = useDebounce(scale, 100) as number
    const dispatch = useDispatch<AppDispatch>()

    const pageRefs = useRef<{ [pageNumber: number]: HTMLDivElement | null }>({})
    const docRef = useRef<HTMLDivElement>(null)

    const pageLoadLock = useMemo(() => new Mutex(), [])

    const reducedHighlights = useMemo(
      () =>
        Array.from(
          new Set(
            resource.extracts
              .flatMap((v) => {
                return {
                  highlight:
                    v.highlight?.['highlight-3'] ||
                    v.highlight?.['highlight-2'] ||
                    v.highlight?.['highlight-1'] ||
                    '',
                  page_number: v.page_number,
                }
              })
              .filter((v) => v.highlight.length > 0)
          )
        ),
      [resource.extracts]
    )

    const isWeb = resource.id.startsWith('web')
    const isOutlook = resource.id.startsWith('microsoft_outlook') || resource.doc_metadata?.document_source === 'outlook'
    const isTeams = resource.id.startsWith('microsoft_teams')  || resource.doc_metadata?.document_source === 'teams'
    const canZoom = !isWeb && !isOutlook && !isTeams

    const emailReceiver =
      isOutlook && Array.isArray(resource.doc_metadata?.receiver)
        ? resource.doc_metadata.receiver.at(0)?.email_address
        : ''
    const ccEmails =
      isOutlook && Array.isArray(resource.doc_metadata?.receiver)
        ? resource.doc_metadata?.receiver?.slice(
            1,
            resource.doc_metadata.receiver.length - 1
          ) || []
        : []

    function onDocumentLoadSuccess({ numPages }: { numPages: number }): void {
      setNumPages(numPages)
      setDocumentLoaded(true)
    }

    const updateLink = useCallback(async () => {
      setNumPages(0)
      setSelectedExtractIndex(0)
      setSelectedResourceLink(null)

      try {
        if (!resource) return
        if (isWeb || isOutlook || isTeams) {
          setError(null)
          return
        }

        if (
          resource.document_type_friendly.toLowerCase() !== 'pdf' &&
          !resource.title.endsWith('.pdf')
        ) {
          setError('unsupported')
          setSelectedResourceLink(null)
          setLoading(false)
          return
        }
        setLoading(true)
        setError(null)

        const resourceId = resource.id
        const result = await dispatch(
          documentApi.endpoints.getResourceData.initiate(getFileId(resource.id))
        )

        if (resource.id === resourceId) {
          if (result.isSuccess) {
            setSelectedResourceLink(result.data)
          }
        }
      } catch {
        setError('source')
        setLoading(false)
      }
    }, [isOutlook, isTeams, isWeb, resource, dispatch])

    const jumpToSelectedExtract = useCallback(() => {
      const extract = reducedHighlights[selectedExtractIndex]
      if (!extract?.page_number) return
      
      const item = pageRefs.current[extract.page_number]
      const container = docRef.current

      if (container && item) {
        const itemOffset = item.offsetTop
        const scrollPosition =
          itemOffset - (container.clientHeight - item.clientHeight) / 2
        container.scrollTo({
          top: scrollPosition,
          behavior: 'smooth',
        })
      }
    }, [reducedHighlights, selectedExtractIndex])

    const handlePageLoadSuccess = (pageNumber: number) => {
      setLoadedPages((loadedPages) =>
        Array.from(new Set([...loadedPages, pageNumber]))
      )
    }

    const handlePageLoaded = useCallback(() => {
      const firstExtract = reducedHighlights.at(0)

      if (firstExtract) {
        const pageNumber = firstExtract.page_number

        if (pageNumber) {
          if (loadedPages.includes(pageNumber)) {
            if (initialJump) {
              setSelectedExtractIndex(0)
              jumpToSelectedExtract()
              setInitialJump(false)
            }

            setLoading(false)
          }
        } else {
          if (loadedPages.length === numPages && numPages > 0) {
            setLoading(false)
          }
        }
      }
    }, [
      initialJump,
      jumpToSelectedExtract,
      loadedPages,
      numPages,
      reducedHighlights,
      setSelectedExtractIndex,
    ])

    const documentRenderer = useMemo(() => {
      try {
        return (
          <PDFWorker>
            <Document
              key={`document-${resource.id}`}
              file={selectedResourceLink}
              options={reactPDFOptions}
              onLoadSuccess={onDocumentLoadSuccess}
              className="mx-auto w-fit [&_.react-pdf\_\_Page\_\_textContent]:!mix-blend-multiply"
              loading={<></>}
              error={<></>}
              noData={<></>}
              onSourceError={() => {
                setLoading(false)
                setError('source')
              }}
              onError={() => {
                setLoading(false)
                setError('generic')
              }}
              onLoadError={() => {
                setLoading(false)
                setError('generic')
              }}
            >
              {documentLoaded &&
                Array.from({ length: numPages }).map((_, index) => {
                  return (
                    <div
                      key={`pdf-${index + 1}-${resource.id}`}
                      ref={(el) => {
                        pageRefs.current[index + 1] = el
                      }}
                    >
                      <DocumentPage
                        pageNumber={index + 1}
                        initialWidth={initialWidth}
                        scale={debouncedScale}
                        highlight={reducedHighlights[selectedExtractIndex]}
                        onLoadSuccess={handlePageLoadSuccess}
                        pageLoadLock={pageLoadLock}
                      />
                    </div>
                  )
                })}
            </Document>
          </PDFWorker>
        )
      } catch (e) {
        handleError(e)
        return <DocumentPreviewGenericError />
      }
    }, [
      selectedResourceLink,
      reducedHighlights,
      debouncedScale,
      documentLoaded,
      initialWidth,
      numPages,
      pageLoadLock,
      resource.id,
      selectedExtractIndex,
    ])

    useEffect(() => {
      updateLink()
    }, [resource, updateLink])

    useEffect(() => {
      if (!initialJump) {
        jumpToSelectedExtract()
      }
    }, [selectedExtractIndex, initialJump, jumpToSelectedExtract])

    useEffect(() => {
      handlePageLoaded()
    }, [handlePageLoaded])

    return (
      <>
        <div
          className={`flex ${type === DocumentPreviewType.SEARCH ? 'p-4 pt-6 border-b' : ''} gap-4  border-system-border-regular`}
        >
          <div
            className={`flex flex-col gap-0 w-full ${type === DocumentPreviewType.ASK || type === DocumentPreviewType.REPORT ? 'justify-end' : ''}`}
          >
            <TypographyLabel className="text-system-body">
              Preview
            </TypographyLabel>

            {type === DocumentPreviewType.SEARCH && (
              <TypographyBody
                isStrong={true}
                className="text-system-primary line-clamp-1 break-all w-full"
              >
                {isTeams || isOutlook ? resource.text : resource?.title}
              </TypographyBody>
            )}
          </div>

          <div className="flex gap-4 ml-auto mr-[48px] items-center mt-auto">
            <Button variant="tertiary" disabled={!canZoom}>
              <ZoomOut
                className={`size-6 shrink-0 stroke-[1.5px] ${!canZoom ? 'opacity-15' : 'opacity-100'}`}
                onClick={() => {
                  setScale((prevScale) => Math.max(prevScale - 0.1, 0))
                  setInitialJump(false)
                }}
              />
            </Button>

            <Button variant="tertiary" disabled={!canZoom}>
              <ZoomIn
                className={`size-6 shrink-0 stroke-[1.5px] ${!canZoom ? 'opacity-15' : 'opacity-100'}`}
                onClick={() => {
                  setScale((prevScale) => Math.min(prevScale + 0.1, 50))
                  setInitialJump(false)
                }}
              />
            </Button>

            <Button
              variant="tertiary"
              onClick={() => {
                if (!resource) return

                handleOpenLink({
                  id: resource.id,
                  url: resource.url,
                  title: resource?.title,
                  documentLink: resource.document_link,
                  documentSource: resource.doc_metadata?.source,
                  window,
                })
              }}
            >
              <ExternalLink className="size-6 shrink-0 stroke-[1.5px]" />
            </Button>
          </div>

          <div className="flex gap-1 items-center mt-auto min-w-[150px] shrink-0">
            {reducedHighlights.length > 0 && !isOutlook && !isTeams && (
              <>
                <TypographyLabel className="text-system-body">
                  Results
                </TypographyLabel>

                <Button
                  variant="tertiary"
                  className={`${selectedExtractIndex > 0 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                  onClick={() => {
                    setSelectedExtractIndex(selectedExtractIndex - 1)
                  }}
                >
                  <ChevronLeft className="size-6 shrink-0 stroke-[1.5px]" />
                </Button>
                <TypographyBody className="text-system-body whitespace-nowrap">
                  {`${selectedExtractIndex + 1} of ${reducedHighlights.length}`}
                </TypographyBody>
                <Button
                  variant="tertiary"
                  className={`${selectedExtractIndex < reducedHighlights.length - 1 ? 'opacity-100' : 'opacity-0 pointer-events-none'}`}
                  onClick={() => {
                    setSelectedExtractIndex(selectedExtractIndex + 1)
                  }}
                >
                  <ChevronRight className="size-6 shrink-0 stroke-[1.5px]" />
                </Button>
              </>
            )}
          </div>
        </div>

        <div
          ref={docRef}
          className={`relative h-[calc(100%-87px)] ${isWeb || isOutlook || isTeams ? '' : 'bg-system-border-light '} overflow-y-auto overflow-x-auto w-full selection:bg-highlight-selected  [&_mark]:!bg-citation-hover [&_mark]:text-transparent`}
        >
          {loading && (
            <div className="flex w-full h-[calc(100%-88px)] absolute top-[44px] left-0 items-center justify-center">
              <img
                className="size-12 animate-logo-animation"
                src="https://cdn.prod.website-files.com/66bccd21862b191477dc8806/66bce0be1491cc6deb168141_symbol.svg"
              />
            </div>
          )}

          {error === 'generic' && (
            <DocumentPreviewGenericError onDismiss={() => setError(null)} />
          )}
          {error === 'source' && (
            <DocumentPreviewSourceError onDismiss={() => setError(null)} />
          )}
          {error === 'unsupported' && (
            <DocumentPreviewUnsupportedTypeError
              onDismiss={() => setError(null)}
            />
          )}

          {isWeb && (
            <div className="m-2 p-6 border-l-4 border-system-placeholder bg-system-surface-light font-body text-system-body whitespace-pre-wrap">
              {reducedHighlights[selectedExtractIndex]?.highlight.replaceAll(
                '\\n',
                '\n'
              ) || ''}
            </div>
          )}

          {(isOutlook || isTeams) && (
            <div
              className={`flex flex-col min-h-full gap-6 p-6 bg-system-surface-light font-body text-system-body whitespace-pre-wrap ${isOutlook ? 'rounded-lg' : 'border-l-4 border-system-placeholder'}`}
            >
              <Markdown
                options={{
                  overrides: {
                    Highlight: {
                      component: ({ children }: { children: ReactNode }) => (
                        <span className="bg-[linear-gradient(0deg,_var(--citation-hover),_var(--citation-hover))] bg-no-repeat bg-[length:100%_90%]">
                          {children}
                        </span>
                      ),
                    },
                  },
                  forceBlock: true,
                  wrapper: 'span',
                }}
              >
                {`<span className='font-body-strong text-system-primary break-all'>${replaceMicrosoftHighlight(resource.doc_metadata?.summary || '')}  </span>`}
              </Markdown>

              <Divider className="bg-system-border-regular" />

              {isOutlook && (
                <div className="flex flex-col gap-3">
                  <TypographyLabel className="text-system-body">
                    Found in:
                  </TypographyLabel>

                  <TypographyBody isStrong className="text-system-primary">
                    {resource.title}
                  </TypographyBody>

                  <div className="flex flex-col gap-1">
                    <TypographyLabel className="text-system-body line-clamp-1 break-all">
                      From:{' '}
                      <span className="font-label-strong text-system-primary">
                        {resource.doc_metadata?.sender?.email_address || ''}
                      </span>
                    </TypographyLabel>

                    <TypographyLabel className="text-system-body line-clamp-1 break-all">
                      To:{' '}
                      <span className="font-label-strong text-system-primary">
                        {emailReceiver}
                      </span>
                    </TypographyLabel>

                    {ccEmails.length > 0 && (
                      <TypographyLabel className="text-system-body line-clamp-1 break-all">
                        CC:{' '}
                        <span className="font-label-strong text-system-primary">
                          {ccEmails.map((v) => v.email_address).join(', ')}
                        </span>
                      </TypographyLabel>
                    )}
                  </div>
                </div>
              )}

              {isTeams && (
                <div className="flex flex-col gap-3">
                  <TypographyLabel className="text-system-body">
                    Found in:
                  </TypographyLabel>

                  <div className="flex flex-col gap-1">
                    <TypographyLabel className="text-system-body line-clamp-1 break-all">
                      From:{' '}
                      <span className="font-label-strong text-system-primary">
                        {resource.doc_metadata?.sender?.name || ''}
                      </span>
                    </TypographyLabel>
                  </div>
                </div>
              )}

              {resource.doc_metadata?.resource_attachments?.map(
                (attachment, index) => {
                  const fileExtension = attachment.name.split('.').at(-1) || ''
                  return (
                    <div
                      key={`resource-attachment-${index}`}
                      className="flex gap-2 py-1 px-3 bg-system-secondary border border-system-border-light h-12 items-center w-fit"
                    >
                      <Paperclip className="size-6 shrink-0 stroke-[1.5px] stroke-system-body mr-1" />

                      {getFileIcon(
                        fileExtension,
                        'size-6 min-w-6 min-h-6 shrink-0'
                      )}

                      <TypographyBody className="text-system-primary line-clamp-1 break-all">
                        {shortString(attachment.name, 30)}
                      </TypographyBody>
                    </div>
                  )
                }
              )}

              <div
                className="flex flex-col gap-0"
                dangerouslySetInnerHTML={{
                  __html: DOMPurify.sanitize(resource.doc_metadata?.body || ''),
                }}
              />
            </div>
          )}

          {selectedResourceLink &&
            !error &&
            !isWeb &&
            !isOutlook &&
            !isTeams &&
            documentRenderer}
        </div>
      </>
    )
  }
)

export const DocumentPreviewGenericError = ({
  onDismiss,
}: {
  onDismiss?: () => void
}) => {
  return (
    <div className="p-6 w-fit mx-auto">
      <CustomAlert
        variant="error"
        description="We could not load file preview at this time. Try again later."
        dismissable={Boolean(onDismiss)}
        onCloseClick={onDismiss}
      />
    </div>
  )
}

export const DocumentPreviewSourceError = ({
  onDismiss,
}: {
  onDismiss?: () => void
}) => {
  return (
    <div className="p-6 w-fit mx-auto">
      <CustomAlert
        variant="error"
        title="We could not access the file at this time."
        description="You might not have permissions to view this file."
        dismissable={Boolean(onDismiss)}
        onCloseClick={onDismiss}
      />
    </div>
  )
}

export const DocumentPreviewUnsupportedTypeError = ({
  onDismiss,
}: {
  onDismiss?: () => void
}) => {
  return (
    <div className="p-6 w-fit mx-auto">
      <CustomAlert
        variant="info"
        title="We only support the preview of PDF files at the moment"
        description="We’re working hard to add support for more types soon!"
        dismissable={Boolean(onDismiss)}
        onCloseClick={onDismiss}
      />
    </div>
  )
}

export const DocumentPreviewContainer = ({
  resource,
  type,
  initialWidth,
  containerHeight,
  sources,
  selectedSource,
  selectedExtractIndex,
  setSelectedExtractIndex,
  onClose,
  onBack,
  setSelectedSource,
}: {
  resource: DocumentPreviewResource
  type: DocumentPreviewType
  initialWidth: number
  containerHeight?: number
  sources?: SourceDocument[]
  selectedSource?: SourceDocument
  selectedExtractIndex: number
  setSelectedExtractIndex: (index: number) => void
  setSelectedSource?: (source: SourceDocument) => void
  onClose?: () => void
  onBack?: () => void
}) => {
  const documentPreview = useMemo(() => {
    try {
      if (
        [
          DocumentPreviewType.ASK,
          DocumentPreviewType.REPORT,
          DocumentPreviewType.DOCGEN,
        ].includes(type)
      ) {
        const currentExtractIndex = (sources || []).findIndex(
          (v) => v.document_id === selectedSource?.document_id
        )
        const nextExtract =
          currentExtractIndex < (sources || []).length - 1
            ? sources?.[currentExtractIndex + 1]
            : undefined
        const previousExtract =
          currentExtractIndex > 0
            ? sources?.[currentExtractIndex - 1]
            : undefined
        const isWeb = resource.id.startsWith('web')
        const isOutlook = resource.id.startsWith('microsoft_outlook') || resource.doc_metadata?.document_source === 'outlook'
        const isTeams = resource.id.startsWith('microsoft_teams') || resource.doc_metadata?.document_source === 'teams'

        const getPreviewHeight = () => {
          if (type === DocumentPreviewType.ASK) {
            return 'calc(100vh - 92px - 24px)'
          }

          if (type === DocumentPreviewType.DOCGEN) {
            return `${containerHeight || 0}px`
          }

          return 'calc(100vh - 224px - 24px)'
        }

        return (
          <div
            onClick={(e) => e.stopPropagation()}
            style={{
              height:
                isWeb || isOutlook || isTeams
                  ? undefined
                  : `${getPreviewHeight()}`,
              maxHeight:
                isWeb || isOutlook || isTeams
                  ? `${getPreviewHeight()}`
                  : undefined,
            }}
            className={`flex flex-col py-3 px-4 gap-3 w-full bg-system-secondary border border-system-border-regular rounded-lg`}
          >
            <div className="flex">
              <Button variant="tertiary" onClick={() => onBack?.()}>
                <div className="flex gap-2 items-center">
                  <ChevronLeft className="size-6 shrink-0 stroke-[1.5px]" />
                  Back
                </div>
              </Button>

              <Button
                variant="tertiary"
                className="ml-auto"
                onClick={() => onClose?.()}
              >
                <X className="size-6 shrink-0 stroke-[1.5px]" />
              </Button>
            </div>

            <SourceHeader
              id={resource.id}
              url={resource.url}
              title={resource.title}
              text={resource.text}
              documentLink={resource.document_link}
              metadata={resource.doc_metadata}
            />

            <div className="flex gap-2">
              <SourceContent
                id={resource.id}
                url={resource.url}
                title={resource.title}
                text={resource.text}
                documentLink={resource.document_link}
                metadata={resource.doc_metadata}
                isSearchResult={true}
              />

              <div className="flex gap-6 ml-auto items-center">
                {previousExtract && (
                  <div className="flex gap-2 items-center">
                    <Button
                      variant="tertiary"
                      className="h-fit"
                      onClick={() => setSelectedSource?.(previousExtract)}
                    >
                      <ChevronLeft className="size-6 shrink-0 stroke-[1.5px]" />
                    </Button>

                    <SourceIcon
                      id={previousExtract.document_id}
                      url={previousExtract.url || ''}
                      metadata={previousExtract.doc_metadata}
                    />
                  </div>
                )}

                {nextExtract && (
                  <div className="flex gap-2 items-center">
                    <SourceIcon
                      id={nextExtract.document_id}
                      url={nextExtract.url || ''}
                      metadata={nextExtract.doc_metadata}
                    />

                    <Button
                      variant="tertiary"
                      className="h-fit"
                      onClick={() => setSelectedSource?.(nextExtract)}
                    >
                      <ChevronRight className="size-6 shrink-0 stroke-[1.5px]" />
                    </Button>
                  </div>
                )}
              </div>
            </div>

            <Divider />
            <DocumentPreview
              resource={resource}
              type={type}
              initialWidth={initialWidth}
              selectedExtractIndex={selectedExtractIndex}
              setSelectedExtractIndex={setSelectedExtractIndex}
            />
          </div>
        )
      } else {
        return (
          <div className="w-full max-h-[calc(100vh-244px-68px)] max-w-[836px] bg-system-secondary border border-system-border-regular rounded-lg overflow-hidden">
            <DocumentPreview
              resource={resource}
              type={type}
              initialWidth={initialWidth}
              selectedExtractIndex={selectedExtractIndex}
              setSelectedExtractIndex={setSelectedExtractIndex}
            />
          </div>
        )
      }
    } catch {
      return <DocumentPreviewGenericError />
    }
  }, [
    resource,
    selectedExtractIndex,
    setSelectedExtractIndex,
    selectedSource,
    sources,
    initialWidth,
    onBack,
    onClose,
    setSelectedSource,
    type,
    containerHeight,
  ])

  return documentPreview
}
