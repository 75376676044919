import { DedupedSearchQueryItem } from "@/types/types";
import { highlightWordFromString, replaceMicrosoftHighlight } from "@/utils/utils";
import Markdown from "markdown-to-jsx";
import { ReactElement } from "react";
import { Card } from "../ui/card";
import { SourceContent } from "../Source";
import { TypographyLabel } from "../ui/Typography";
import Divider from "../ui/divider";
import { CustomTooltip } from "../CustomTooltip";
import { formatDate } from "date-fns";

function Highlight(props: { children: ReactElement }) {
    return <span className="inline-block font-medium text-system-primary citation-background">{props.children}</span>
}

export function SearchResult({ resource: d, query, selectedExtractIndex, selected }: { resource: DedupedSearchQueryItem, query: string, selectedExtractIndex: number, selected: boolean, onAnalyseClick?: () => void }) {
    const reducedHighlights = Array.from(new Set(d.extracts.flatMap((v) => {
        return {
            highlight: v.highlight?.["highlight-3"] || v.highlight?.["highlight-2"] || v.highlight?.["highlight-1"] || '',
            page_number: v.page_number
        }
    })
        .filter((v) => v.highlight.length > 0)))

    const updatedDateString = formatDate(new Date(d.document_updated_at_desia || d.document_created_at_desia), 'd MMM yyyy')

    const isOutlook = d?.document_source === 'outlook'
    const isTeams = d?.document_source === 'teams'
    const formattedSelectedHighlight = isOutlook || isTeams ? replaceMicrosoftHighlight(d.doc_metadata?.summary || '') :  highlightWordFromString((reducedHighlights[selectedExtractIndex]?.highlight || '').replaceAll('\\n', '&nbsp;'), query)

    return (
        <>
            <Card key={`search_result_card_${d.id}`} className={`p-3 shadow-none cursor-pointer group w-full border-none inner-border inner-border-system-border-regular hover:bg-system-hover active:inner-border-2 active:inner-border-system-body focus-visible:inner-border-4 focus-visible:inner-border-system-primary focus-visible:outline-none ${selected ? 'inner-border-2 inner-border-system-primary ' : ''}`}>
                <div className=''>
                    <div className='flex flex-col gap-2'>
                        <div className="flex flex-wrap gap-2 mobile:items-start">
                            <div className="flex-grow">
                                <div className={`flex gap-2 items-start mt-1`}>
                                    <div className={`mt-[2px] max-w-[calc(100%-32px)]`}>
                                        <TypographyLabel className="text-system-body whitespace-nowrap overflow-hidden text-ellipsis">
                                            {updatedDateString}
                                        </TypographyLabel>
                                    </div>
                                </div>
                            </div>
                            {reducedHighlights.length > 0 && (
                                <div className="flex gap-3 items-center md:shrink-0 min-h-8">
                                    <TypographyLabel className="text-system-body">
                                        {`${reducedHighlights.length} results`}
                                    </TypographyLabel>
                                </div>
                            )}
                        </div>

                        <Divider />
                        <div className={`flex flex-col ${d.document_source_path ? 'gap-6' : 'gap-2'}`}>
                            <div className="relative min-h-[42px] flex items-center">
                                <div className="flex-grow w-full max-w-full pr-2">
                                    <SourceContent
                                        id={d.id}
                                        url={d.url}
                                        title={isOutlook || isTeams ? d.text : d.title}
                                        documentLink={d.document_link}
                                        metadata={{
                                            document_is_part_of_desia_library: d.document_is_part_of_desia_library,
                                            document_type_friendly: d.document_type_friendly,
                                            document_updated_at_desia: d.document_updated_at_desia,
                                            document_source: d.document_source,
                                            document_source_details: d.document_source_details,
                                            document_source_path: d.document_source_path,
                                            ...d.doc_metadata
                                        }}
                                        isSearchResult={true}
                                    />
                                </div>
                            </div>
                            {reducedHighlights.length > 0 && (
                                <div className="">
                                    <div className={`text-system-body font-label break-all`}>
                                        <Markdown options={{
                                            overrides: {
                                                em: {
                                                    component: Highlight
                                                },
                                                Highlight: {
                                                    component: Highlight
                                                },
                                            },
                                            wrapper: 'span',
                                            forceBlock: true,
                                        }}>
                                            {`<span>${formattedSelectedHighlight}</span>`}
                                        </Markdown>
                                    </div>
                                </div>
                            )}
                        </div>
                    </div>
                </div>
            </Card >
            <CustomTooltip
                id={`analyse-source-tooltip-${d.id}`}
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
            >
                <TypographyLabel>
                    Analyse
                </TypographyLabel>
            </CustomTooltip>
            <CustomTooltip
                id={`open-source-link-tooltip-${d.id}`}
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
            >
                <TypographyLabel>
                    Open
                </TypographyLabel>
            </CustomTooltip>
        </>

    )
}
