import { DedupedSearchQueryItem, DocumentPreviewResource, DocumentPreviewType } from "@/types/types";
import { Skeleton } from "../ui/skeleton";
import { SearchResult } from "./SearchResult";
import { ErrorMessage } from "../ErrorMessage";
import { NotAvailableDialog } from "./NotAvailableDialog";
import { useEffect, useMemo, useState } from "react";
import { TypographyBody } from "../ui/Typography";
import { DocumentPreviewContainer } from "./DocumentPreview";

export function SearchResults({ error, loading, data, query }: { error: any, loading: boolean, data: DedupedSearchQueryItem[], query: string }) {
    const [showDialog, setShowDialog] = useState(false)
    const [selectedResource, setSelectedResource] = useState<DedupedSearchQueryItem | null>(null)
    const [selectedExtractIndex, setSelectedExtractIndex] = useState<{ [id: string]: number }>({})
    
    const previewResource = useMemo(() => {
        return {
            extracts: selectedResource?.extracts,
            document_type_friendly: selectedResource?.document_type_friendly,
            id: selectedResource?.id,
            title: selectedResource?.title,
            text: selectedResource?.text,
            url: selectedResource?.url,
            document_link: selectedResource?.document_link,
            doc_metadata: { ...selectedResource?.doc_metadata, ...selectedResource },
        } as DocumentPreviewResource
    }, [selectedResource])

    useEffect(() => {
        if (!selectedResource || !data.includes(selectedResource)) {
            setSelectedResource(data.at(0) || null)
        }
    }, [data, selectedResource])

    useEffect(() => {
        const id = selectedResource?.id

        if (!id) return
        setSelectedExtractIndex({
            ...selectedExtractIndex,
            [id]: 0
        })
    }, [selectedResource])

    if (loading) {
        return (
            <div className="flex gap-4 sm:gap-6 mx-auto w-full">
                <div className="flex flex-col gap-4 sm:gap-6 w-full">
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                </div>

                <div className="flex flex-col gap-4 sm:gap-6 w-full">
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                    <Skeleton className="h-60 w-full" />
                </div>
            </div>
        )
    }
    if (error) {
        return (
            <ErrorMessage message="We failed to search for resources, please try again shortly" />
        )
    }

    return (
        <>
            {data.length === 0 && (
                <TypographyBody className="text-system-body text-center whitespace-pre-wrap">
                    {`We could not find any results.\nTry again with different keywords or check your spelling.`}
                </TypographyBody>
            )}

            <div className="flex gap-0.5 mx-auto w-full max-w-[calc(100vw-32px)]">
                <div className="h-[calc(100vh-296px)] flex flex-col gap-4 min-w-[500px] max-w-[500px] overflow-y-auto pr-3.5 pb-4">
                    {data.map((resource) => {
                        return (
                            <div
                                key={`search-result-${resource.id}`}
                                onClick={() => {
                                    setSelectedResource(resource)
                                }}
                            >
                                <SearchResult resource={resource} key={resource.id} selectedExtractIndex={selectedExtractIndex[resource.id] || 0} query={query} selected={resource.id === selectedResource?.id} />
                            </div>
                        )
                    })}
                </div>
                {selectedResource && (
                    <DocumentPreviewContainer
                        key={`document-preview-container-${selectedResource.id}`}
                        type={DocumentPreviewType.SEARCH}
                        initialWidth={600}
                        resource={previewResource}
                        selectedExtractIndex={selectedExtractIndex[selectedResource.id] || 0}
                        setSelectedExtractIndex={(index) => {
                            setSelectedExtractIndex({
                                ...selectedExtractIndex,
                                [selectedResource.id]: index
                            })
                        }}
                    />
                )}
            </div>

            <NotAvailableDialog
                open={showDialog}
                setOpen={(v) => setShowDialog(v)}
                variant='doc-analysis'
            />
        </>
    )
}
