import { SearchConnectors } from '@/types/types'

export const getSearchConnectors = (searchConnectors: SearchConnectors) => {
  const connectors = []

  if (searchConnectors.internalSearch) {
    connectors.push('internal')
  }

  if (searchConnectors.outlookSearch) {
    connectors.push('msft-outlook')
  }

  if (searchConnectors.teamsSearch) {
    connectors.push('msft-teams')
  }

  return connectors
}
