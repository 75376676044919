import { ResponseChartData } from "@/types/types";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { ChartHeader } from "./ChartHeader";
import { useEffect, useRef, useState } from "react";
import { formatChartNumber, formatTruncateDataLabel, pxToCh } from "./ResponseChart";

export const ResponseBarChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
}: {
  id: string;
  parsedData: ResponseChartData;
  series: ApexAxisChartSeries;
  options: ApexOptions;
  compact?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const [maxChar, setMaxChar] = useState<number>(5);


  const barAmount = series.reduce((a, b) => a + b.data.length, 0);

  const fontSizeInPx = 14;  

  const barOptions: ApexOptions = {
    ...options,
    chart: {
      ...options.chart,
      type: "bar",
    },
    yaxis: {
      show:false,
    },
    stroke: {
      colors: ["transparent"],
      width: 2,
    },
    dataLabels: {
      enabled: true,
      formatter: (val: string) => {
        return formatTruncateDataLabel({
          label: formatChartNumber(val) as string,
          maxChar: maxChar,
          categoryAmount: barAmount
        });
      },
      offsetY: -20,
      style: {
        fontSize: `${fontSizeInPx}px`,
        fontFamily: "Inter, sans-serif",
        fontWeight: 400,
        colors: ["#0a0a0a"],
      },
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions?.bar,
        columnWidth: barAmount <= 5 ? "50%" : "90%",
        dataLabels: {
          ...options.plotOptions?.bar?.dataLabels,
          
          position: "top",
          total: {
            enabled: true,
            style: {
              fontSize: `${fontSizeInPx}px`,
              fontFamily: "Inter, sans-serif",
              fontWeight: 400,
            },
            offsetY: -4,
          },
        },
      },
    },
  };

  useEffect(()=>{
    const parentElement = document.getElementById(`chart-container-${id}`)

    const barElements: NodeListOf<HTMLDivElement> = parentElement?.querySelectorAll(
      `.apexcharts-bar-area`,
    ) as NodeListOf<HTMLDivElement>;

    if(barElements && barElements.length > 0) {
      const barWidth = barElements[0].getAttribute("barWidth")
      if(barWidth){
        const barWidthInt = parseInt(barWidth)
        const calcMaxChar = pxToCh(barWidthInt, fontSizeInPx)
        setMaxChar(calcMaxChar)
      }
    }

  },[id])

  return (
    <div
      className={`flex flex-col gap-4 p-4 ${compact ? "mt-8" : "mt-10"} relative group`}
    >
      <ChartHeader chartRef={ref} parsedData={parsedData} compact={compact} />

      <div className="relative w-full h-[300px]">
        <div
          className={`absolute max-w-full ${barAmount > 15 ? "w-fit" : "w-full"} laptop:max-w-[1200px] laptop:left-[50%] laptop:-translate-x-[50%] overflow-x-auto laptop:overflow-x-visible overflow-y-visible h-[300px]`}
        >
          <div
            ref={ref}
            id={`chart-container-${id}`}
            className={`${barAmount > 30 ? "w-[1200px]" : barAmount > 15 ? "w-[1000px]" : "w-full"} h-full [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary`}
          >
            <Chart
              options={barOptions}
              series={series}
              type="bar"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
