import { FinalAnswer } from "@/components/Assistant/FinalAnswer";
import { Citation, SourceDocument } from "@/types/types";
import { getCaretPosition, setCaretPosition } from "@/utils/utils";
import { CustomTooltip } from '../../../components/CustomTooltip'
import { API } from "@editorjs/editorjs";
import ReactDOM from "react-dom/client";
import { Button } from "@/components/ui/button";
import { Provider } from "react-redux";
import { store } from "@/store/store";

interface Data {
    text: string,
    citations: Citation[],
    documents: SourceDocument[],
}

export class FinalAnswerBlock {
    private container: HTMLDivElement;
    private root: ReactDOM.Root | null = null;
    private data: Data;
    private text: string
    private citations: Citation[];
    private isTooltipShown = false
    private removedCitations = false
    private timeout: NodeJS.Timeout | null = null
    private api: API;
    private id: string;

    static get conversionConfig() {
        return {
            export: (data: Data) => {
                return data.text
            },
        };
    }

    static get pasteConfig() {
        return {
            tags: []
        }
    }

    constructor({ api, data }: { api: API, data: Data }) {
        this.data = data;
        this.api = api;
        this.text = data.text
        this.citations = data.citations
        this.container = document.createElement("div")
        this.id = crypto.randomUUID();
    }

    inputHandler = () => {
        const caretPosition = getCaretPosition(this.container)
        this.text = document.getElementById(this.id)?.innerText as string;

        if (!this.removedCitations) {
            this.removeCitations()
        }
        
        if (this.timeout && this.isTooltipShown) {
            clearTimeout(this.timeout)
            this.timeout = setTimeout(() => {
                this.isTooltipShown = false
                this.renderContainer()
            }, 3000)
        }

        this.renderContainer()

        setTimeout(() => {
            setCaretPosition(caretPosition, this.container)
        }, 1);
    };

    cleanMarkdown = (text: string): string =>{
        let cleanedText = text
        cleanedText = cleanedText.replace(/^#{1,6}\s+/g, ''); 

        cleanedText = cleanedText.replace(/\*\*(.*?)\*\*/g, '$1');
        
        return cleanedText;
    }

    keyDownHandler = (e: React.KeyboardEvent<HTMLDivElement>) => { 
        if (e.key === "Enter" && e.shiftKey) {
            if (!this.removedCitations) {
                this.removeCitations()
            }
            const cleanedText = this.cleanMarkdown(this.data.text)
            this.api.blocks.insert(
              'NewParagraph', 
              {
                text: cleanedText
              }, 
              undefined,
              this.api.blocks.getCurrentBlockIndex(),
              true,
              true
            );
        }
        else if (e.key === 'Enter' && !e.shiftKey) {
            e.preventDefault();
            this.api.blocks.insert('NewParagraph', {}, undefined, this.api.blocks.getCurrentBlockIndex() + 1, true);
        }
   }

    removeCitations = () => {
        this.citations = []
        this.removedCitations = true

        if (this.data.citations.length > 0) {
            this.isTooltipShown = true

            // rerender tooltip to account for sources component dissappearing
            setTimeout(() => {
                this.renderContainer()
            }, 600)

            this.timeout = setTimeout(() => {
                this.isTooltipShown = false
                this.renderContainer()
            }, 3000)
        }
    }

    undoEdit = () => {
        this.isTooltipShown = false
        this.removedCitations = false
        setTimeout(() => {
            this.citations = this.data.citations
            this.text = this.data.text
            this.renderContainer()
        }, 1)
    }

    render() {
        this.root = ReactDOM.createRoot(this.container)

        this.renderContainer()

        setTimeout(() => {
            this.api.caret.setToBlock(this.api.blocks.getCurrentBlockIndex())
        }, 1)

        setTimeout(() => {
            setCaretPosition(this.data.text.length + this.data.citations.length, this.container)
        }, 2);

        return this.container
    }

    renderContainer() {
        this.root?.render(
            <Provider store={store}>
                <div>
                    <span id={this.id} data-tooltip-id="citation-removed" data-tooltip-place="bottom" className="focus:outline-none" contentEditable suppressContentEditableWarning onInput={this.inputHandler} onKeyDown={this.keyDownHandler}>
                        <FinalAnswer
                            isLoading={false}
                            isComplete={true}
                            text={this.text}
                            citations={this.citations}
                            documents={this.data.documents}
                            compact={false}
                            legacyCitation={true}
                        />
                    </span>
                    <CustomTooltip
                        id="citation-removed"
                        clickable={true}
                        className="!py-0 !px-3"
                        isOpen={this.isTooltipShown}
                        largeArrow={false}
                        followCaret={true}
                    >
                        <div className="flex gap-2 items-center">
                            Citations removed.

                            <Button variant='inline' className="pt-[2px]" onClick={() => { this.undoEdit() }}>
                                Undo
                            </Button>
                        </div>
                    </CustomTooltip>
                </div>
            </Provider>
        )
    }

    save() {
        return {
            text: this.text,
            citations: this.citations,
            documents: this.data.documents
        }
    }
}
