import { useNavigate, useSearchParams } from 'react-router-dom'
import { TypographyH3 } from '../ui/Typography'
import { useCallback, useContext, useEffect, useState } from 'react'
import { AskDesiaLink } from './AskDesiaLink'
import { SearchResults } from './SearchResults'
import {
  DedupedSearchQueryItem,
  DESIA_EVENT,
  ResponseSearchQuery,
  WebSocketResponseWrapper,
} from '@/types/types'
import { groupByFile } from '@/utils/search'
import { WebSocketContext } from '@/contexts/WebSocketContext'
import { Searchbar } from '../Searchbar'
import { getTimestamp } from '@/utils/utils'
import { SearchSourceSelector } from './SearchSourceSelector'
import { useSelector } from 'react-redux'
import { RootState } from '@/store/store'
import { getSearchConnectors } from './utils'

export function ResourceSearchPage({
  loading,
  error,
  data,
  handleSearch: onExecuteSearch,
}: {
  loading: boolean
  error: boolean
  data: WebSocketResponseWrapper<ResponseSearchQuery> | null
  handleSearch: (q: string) => void
}) {
  const [searchParams, setSearchParams] = useSearchParams()
  const searchQuery = searchParams.get('q')

  const navigate = useNavigate()

  function handleSearch(nextSearchQuery: string) {
    if (nextSearchQuery === '') {
      return
    }
    setSearchParams({ q: nextSearchQuery })
  }

  function handleAskDesia() {
    navigate(`/assistant/ask?q=${searchQuery}&trigger=true&mode=simple`)
  }

  useEffect(() => {
    if (searchQuery) {
      onExecuteSearch(searchQuery)
    }
  }, [searchQuery, onExecuteSearch])

  useEffect(() => {
    document.title = 'Desia AI - Search results'
  }, [])

  const dedupedResults: DedupedSearchQueryItem[] = groupByFile(data?.data || [])

  return (
    <div className="relative flex flex-col gap-2 -mx-4 mobile:mx-0 tablet:mx-auto mt-14 sm:mt-0 max-w-[1340px] !h-screen !overflow-hidden">
      <div className="flex flex-col gap-10">
        <div className="flex flex-col gap-6">
          <AskDesiaLink query={searchQuery} handleClick={handleAskDesia} />

          <div className="text-center">
            <TypographyH3>Search results</TypographyH3>
          </div>

          <div className="mx-auto max-w-full w-[calc(100%-72px)] sm:w-[480px]">
            <Searchbar
              type="search-result"
              autoFocus={true}
              initialQuery={searchQuery || undefined}
              handleSearch={handleSearch}
            />
          </div>
        </div>

        <div className="flex flex-col gap-6">
          <SearchSourceSelector />
          <div className="flex flex-col gap-6">
            <SearchResults
              loading={loading}
              error={error}
              data={dedupedResults}
              query={searchQuery || ''}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

export function ResourceSearchPageContainer() {
  const [loading, setLoading] = useState(false)
  const [error, setError] = useState(false)
  const [results, setResults] =
    useState<WebSocketResponseWrapper<ResponseSearchQuery> | null>(null)
  const secureSocket = useContext(WebSocketContext)
  const socket = secureSocket.socket!

  const connectors = useSelector((state: RootState) => state.search.connectors)

  function handleSearchResults(
    res: WebSocketResponseWrapper<ResponseSearchQuery>
  ) {
    setResults(res)
    if (res.error) {
      setError(true)
    } else {
      setError(false)
    }
    setLoading(false)
  }

  const handleSearch = useCallback((q: string) => {
    setLoading(true)
    socket.emit(DESIA_EVENT.SEARCH_QUERY_V2_REQUEST, {
      requestId: `search_query_v2_request_${q}`,
      timestamp: getTimestamp(),
      params: {
        query: q,
        semantic_highlights: 'generative',
        sources: getSearchConnectors(connectors),
      },
    })
  }, [connectors, socket])

  useEffect(() => {
    socket.on(DESIA_EVENT.SEARCH_QUERY_V2_RESPONSE, handleSearchResults)

    return () => {
      socket.off(DESIA_EVENT.SEARCH_QUERY_V2_RESPONSE, handleSearchResults)
    }
  })

  return (
    <ResourceSearchPage
      loading={loading}
      error={error}
      data={results}
      handleSearch={handleSearch}
    />
  )
}
