import { getFileIcon, getIntegrationIcon } from '@/utils/components'
import {
  captialise,
  getDomain,
  getHostname,
  getIconSrc,
  handleOpenLink,
} from '@/utils/utils'
import { ExternalLink, Paperclip, ScanSearch } from 'lucide-react'
import { TypographyLabel } from './ui/Typography'
import { IntegrationCode, SourceDocumentMetadata } from '@/types/types'
import { MiddleTruncate } from '@re-dev/react-truncate'
import { formatDate } from 'date-fns'
import { Checkbox } from './ui/checkbox'
import AvatarPlaceholder from '@/assets/AvatarPlaceholder.svg'
interface SourceProps {
  id: string
  url: string
  title?: string
  text?: string
  documentLink?: string
  showBorder?: boolean
  metadata?: SourceDocumentMetadata
  showCheckbox?: boolean
  selected?: boolean
  previewable?: boolean
  onSelect?: () => void
  onClick?: () => void
}

interface SourceContentProps extends SourceProps {
  isSearchResult?: boolean
}

interface SourceHeaderProps extends SourceProps {
  isSearchResult?: boolean
}

interface SourceIconProps extends SourceProps {
  isSearchResult?: boolean
}

export const Source = ({
  id,
  url,
  title,
  text,
  documentLink,
  showBorder,
  showCheckbox = false,
  selected,
  onSelect,
  metadata,
  previewable,
  onClick,
}: SourceProps) => {
  const style = `flex px-3 pb-2.5 pt-3  group ${showBorder ? 'border' : ''} border-system-border-regular bg-system-secondary rounded-lg cursor-pointer`

  const handleClick = (e: React.MouseEvent<HTMLElement>) => {
    if (!previewable) {
      handleOpenLink({
        id: id,
        url: url,
        title: title || '',
        documentLink: documentLink || metadata?.external_link,
        documentSource: metadata?.document_source,
        window,
      })
    } else {
      e.stopPropagation()
      onClick?.()
    }
  }

  return (
    <div
      key={`source_${id}`}
      className={style}
      onClick={(e) => {
        if (!showCheckbox) {
          handleClick(e)
        }
      }}
    >
      <div className={`flex gap-2 flex-grow max-w-full`}>
        {showCheckbox && (
          <div className="flex items-center">
            <Checkbox
              checked={selected}
              onClick={(e) => {
                e.stopPropagation()
                onSelect?.()
              }}
            />
          </div>
        )}

        <div
          onClick={handleClick}
          className={`flex gap-2 flex-grow max-w-full`}
        >
          <div className={`flex flex-col gap-2 flex-grow max-w-full`}>
            <SourceHeader id={id} url={url} metadata={metadata} />

            <div className="flex items-center group hover:bg-system-hover p-[2px] border-4 border-system-secondary hover:border-system-hover rounded-md active:bg-system-border-light active:border-system-border-light focus-visible:border-system-primary focus-visible:outline-none focus-visible:border-2 focus-visible:p-[2px]">
              <SourceContent
                id={id}
                url={url}
                text={text}
                title={title}
                metadata={metadata}
              />

              <div className="w-6 ml-auto mr-2">
                {previewable ? (
                  <ScanSearch className="w-6 h-6 hidden shrink-0 stroke-[1.5px] group-hover:flex" />
                ) : (
                  <ExternalLink className="w-6 h-6 hidden shrink-0 stroke-[1.5px] group-hover:flex group-focus-visible:flex" />
                )}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export const SourceHeader = ({
  metadata,
  isSearchResult,
}: SourceHeaderProps) => {
  const updatedDateString =
    metadata?.document_sent_at_datetime ||
    metadata?.document_created_at_datetime ||
    metadata?.document_updated_at_desia ||
    metadata?.document_updated_at_source ||
    metadata?.document_created_at_source
  const formattedDateString =
    updatedDateString && formatDate(new Date(updatedDateString), 'd MMM yyyy')

  if (!formattedDateString) return null

  return (
    <div
      className={`flex gap-2 ${isSearchResult ? 'items-start mt-1' : 'items-center'}`}
    >
      <div
        className={`${isSearchResult ? 'mt-[2px]' : ''} max-w-[calc(100%-32px)]`}
      >
        <TypographyLabel className="text-system-body whitespace-nowrap overflow-hidden text-ellipsis">
          {formattedDateString}
        </TypographyLabel>
      </div>
    </div>
  )
}

export const SourceContent = ({
  id,
  url,
  text,
  title,
  metadata,
  isSearchResult,
}: SourceContentProps) => {
  const isThirdPartyData = metadata?.label === 'third_party_data'
  const isDesiaLibrary = metadata?.document_is_part_of_desia_library || false
  const isDocument = !id.startsWith('web')
  const isOutlook = id.startsWith('microsoft_outlook') || metadata?.document_source === 'outlook'
  const isTeams = id.startsWith('microsoft_teams') || metadata?.document_source === 'teams'
  const isIntegration = Boolean(metadata?.document_source_details?.integration_code_name)

  const style = `${isSearchResult ? 'font-body-strong' : 'font-label-strong'} text-system-primary line-clamp-1 break-all`

  const path = metadata?.document_source_path
  const formattedPath =
    'in ' + path?.replaceAll('>', '/').split(' / ').slice(0, -1).join(' / ')
  const end = (formattedPath?.split('/').at(-1)?.length || 0) + 2

  return (
    <div className="flex gap-3 items-center w-full">
      <div className={`${isOutlook || isTeams || isIntegration ? 'mb-1' : ''}`}>
      <SourceIcon id={id} url={url} metadata={metadata} />
      </div>
      <div className="flex flex-col gap-0 flex-grow max-w-[calc(100%-32px)]">
        <div className="flex gap-3 items-center mr-2">
          <div className={style}>{isThirdPartyData ? text : (title || text)}</div>
          {(metadata?.attachment_count || 0) > 0 && (
            <Paperclip className="size-5 shrink-0 stroke-[1.5px] stroke-system-body" />
          )}
        </div>

        {isDesiaLibrary && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            Desia library
          </TypographyLabel>
        )}

        {!isDocument && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {getDomain(getHostname(url))}
          </TypographyLabel>
        )}

        {path && isDocument && !isDesiaLibrary && (
          <>
            <MiddleTruncate
              end={end}
              className="w-full flex-grow font-label text-system-body hidden sm:!flex"
            >
              {formattedPath}
            </MiddleTruncate>
            <TypographyLabel className="w-full flex-grow font-label text-system-body flex sm:!hidden !line-clamp-1">
              {formattedPath}
            </TypographyLabel>
          </>
        )}

        {isDocument && !path && !isDesiaLibrary && (
          <TypographyLabel className="text-system-body whitespace-nowrap overflow-hidden text-ellipsis">
            {captialise(
              metadata?.document_visibility === 'organization'
                ? 'shared'
                : metadata?.document_visibility || ''
            )}
          </TypographyLabel>
        )}

        {isOutlook && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {metadata?.sender?.email_address}
          </TypographyLabel>
        )}

        {isTeams && (
          <TypographyLabel className="text-system-body line-clamp-1 break-all">
            {metadata?.sender?.name}
          </TypographyLabel>
        )}
      </div>
    </div>
  )
}

export const SourceIcon = ({ id, url, metadata }: SourceIconProps) => {
  const isDocument = !id.includes('web')
  const isOutlook = id.startsWith('microsoft_outlook') || metadata?.document_source === 'outlook'
  const isTeams = id.startsWith('microsoft_teams') || metadata?.document_source === 'teams'

  return (
    <div className={`relative size-6`}>
      {isDocument &&
        !isOutlook &&
        !isTeams &&
        getFileIcon(metadata?.document_type_friendly || '', 'w-6 h-6')}

      {(isOutlook || isTeams) && (
        <img className="size-6 min-w-6 shrink-0" src={AvatarPlaceholder} />
      )}

      {metadata?.document_source_details && (
        <div
          className={`absolute -bottom-2 -right-2 flex items-center justify-center size-[18px] rounded-full border border-system-border-light bg-system-secondary`}
        >
          {getIntegrationIcon(
            metadata?.document_source_details?.integration_code_name || '',
            true,
            'w-3.5 shrink-0'
          )}
        </div>
      )}

      {isOutlook && (
        <div
          className={`absolute -bottom-2 -right-2 flex items-center justify-center size-[18px] rounded-full border border-system-border-light bg-system-secondary shrink-0`}
        >
          {getIntegrationIcon(IntegrationCode.OUTLOOK, true, 'w-2.5 shrink-0')}
        </div>
      )}

      {isTeams && (
        <div
          className={`absolute -bottom-2 -right-2 flex items-center justify-center size-[18px] rounded-full border border-system-border-light bg-system-secondary shrink-0`}
        >
          {getIntegrationIcon(IntegrationCode.TEAMS, true, 'w-2.5 shrink-0')}
        </div>
      )}

      {url && !isDocument && (
        <img
          src={getIconSrc(getHostname(url))}
          className={`size-6 min-w-6 min-h-6 border border-system-border-light rounded-sm shrink-0`}
        />
      )}
    </div>
  )
}
