import { MAX_ICONS_INLINE_SOURCES } from '@/constants'
import {
  ChatMessage,
  DESIA_EVENT,
  IntegrationCode,
  RequestAssistantAsk,
  ResponseChatStream,
  SourceDocument,
  SourceType,
  UserMessage,
  WebSocketRequestWrapper,
} from '@/types/types'
import { getIconSrc, getTimestamp } from '@/utils/utils'
import { Button } from '../ui/button'
import {
  Dialog,
  DialogBody,
  DialogContent,
  DialogDescription,
  DialogFooter,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from '@/components/ui/dialog'
import { Sources } from './Sources'
import Stack from '@/assets/Stack'
import {
  getFileIcon,
  getGlobalUniqueDocuments,
  getIntegrationIcon,
} from '@/utils/components'
import { CustomTooltip } from '../CustomTooltip'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { RotateCcw } from 'lucide-react'
import { memo, useContext, useEffect, useState } from 'react'
import shortid from 'shortid'
import { UserContext } from '@/contexts/UserContext'
import { getAskTools } from '@/utils/ask'
import { useSocketQuery } from '@/hooks/useSocketQuery'
import { getLiveConversationById } from './utils'
import { useSelector, useDispatch } from 'react-redux'
import type { RootState, AppDispatch } from '../../store/store'
import { actions as assistantActions } from './assistantSlice'
import { CustomAlert } from '../CustomAlert'
import { deleteLastMessage } from './assistantThunk'

interface Props {
  documents: SourceDocument[]
  compact?: boolean
  maxIcons?: number
  loading?: boolean
  className?: string
  message?: ChatMessage
  sourceType: SourceType
}

interface WebIconsProps {
  documents: SourceDocument[]
  maxIcons?: number
}
interface ViewSourcesProps {
  documents: SourceDocument[]
  compact?: boolean
  message?: ChatMessage
  loading?: boolean
  sourceType: SourceType
  canRetry?: boolean
}

const iconStyle = 'w-4 h-4 border border-system-border-light rounded-[2px]'

function WebIcons({ documents, maxIcons }: WebIconsProps) {
  return (
    <div className="flex gap-1">
      {documents
        .filter((d) => d.document_id.includes('web'))
        .reduce((acc, cur) => {
          const { hostname } = new URL(cur.url || '')
          if (acc.includes(hostname)) {
            return acc
          }
          return [...acc, hostname]
        }, [] as string[])
        .filter(
          (_, idx) => idx < (maxIcons ? maxIcons : MAX_ICONS_INLINE_SOURCES)
        )
        .map((hostname) => {
          const src = getIconSrc(hostname)
          return (
            <div key={`favicon-${hostname}-web`}>
              <img
                src={src}
                className={iconStyle}
                data-tooltip-id={`web-icon-tooltip-${hostname}`}
              />

              <CustomTooltip
                id={`web-icon-tooltip-${hostname}`}
                className="!py-1 !px-3 !rounded-sm"
                largeArrow={false}
              >
                <TypographyLabel>{hostname}</TypographyLabel>
              </CustomTooltip>
            </div>
          )
        })}
    </div>
  )
}

function LibraryIcons({ documents, maxIcons }: WebIconsProps) {
  return (
    <div className="flex gap-1">
      {documents
        .filter((d) => !d.document_id.includes('web'))
        .filter(
          (_, idx) => idx < (maxIcons ? maxIcons : MAX_ICONS_INLINE_SOURCES)
        )
        .map((document, index) => {
          const isDocument = !document.document_id.includes('web')
          const isIntegration = Boolean(
            document.doc_metadata?.document_source_details
              ?.integration_code_name
          )
          return (
            <div
              className={`relative size-4 ${iconStyle} flex justify-center items-center`}
              key={`library-icons-${index}`}
            >
              {isDocument &&
                !isIntegration &&
                getFileIcon(
                  document.doc_metadata?.document_type_friendly || '',
                  '!w-3 !h-3'
                )}

              {isDocument &&
                isIntegration &&
                getIntegrationIcon(
                  document.doc_metadata?.document_source_details
                    ?.integration_code_name || '',
                  true,
                  'w-3 h-3 shrink-0'
                )}
            </div>
          )
        })}
    </div>
  )
}

function CommunicationIcons({ documents, maxIcons }: WebIconsProps) {
  return (
    <div className="flex gap-1">
      {documents
        .filter((d) => !d.document_id.includes('web'))
        .filter(
          (_, idx) => idx < (maxIcons ? maxIcons : MAX_ICONS_INLINE_SOURCES)
        )
        .map((document, index) => {
          const isOutlook = document.document_id.startsWith('microsoft_outlook')
          return (
            <div
              className={`relative size-4 ${iconStyle} flex justify-center items-center`}
              key={`communications-icons-${index}`}
            >
              {getIntegrationIcon(
                isOutlook ? IntegrationCode.OUTLOOK : IntegrationCode.TEAMS,
                true,
                'w-2.5 h-2.5 shrink-0'
              )}
            </div>
          )
        })}
    </div>
  )
}

export function ViewSources({
  documents,
  compact,
  message,
  loading,
  sourceType,
  canRetry = true,
}: ViewSourcesProps) {
  const [open, setOpen] = useState(false)

  const assistantStore = useSelector((state: RootState) => state.assistant)
  const dispatch = useDispatch<AppDispatch>()
  const { settings } = useContext(UserContext)

  const filteredDocuments = getGlobalUniqueDocuments(documents, false)

  const [selectedDocuments, setSelectedDocuments] = useState(filteredDocuments)
  const [showError, setShowError] = useState(false)

  const requestId = message?.requestId || `new_ask_${shortid()}`

  const conversation = getLiveConversationById({
    store: assistantStore,
    conversationId: message?.conversationId || '',
    requestId,
  })

  const { executeQuery, state: queryState } = useSocketQuery({
    event: DESIA_EVENT.CHAT_ASK,
    request: {
      requestId: requestId,
      timestamp: getTimestamp(),
      params: {},
    },
    options: {
      manual: true,
      callback: (response) => {
        const res = response.data as ResponseChatStream // fixme
        dispatch(
          assistantActions.streamingResponse({
            ...res,
            requestId: response.requestId,
            timestamp: response.timestamp,
          })
        )
      },
    },
  })

  const isLoading = loading || queryState.loading

  function handleSubmit() {
    if (selectedDocuments.length === 0) {
      setShowError(true)
      return
    } else {
      setShowError(false)
    }

    const systemMessageIndex = conversation.findIndex((v) => v === message)
    const userMessage = conversation[systemMessageIndex - 1] as UserMessage
    const timestamp = getTimestamp()
    const query = userMessage.query || ''

    const conversationId = conversation.at(-1)?.conversationId

    if (!conversationId) return

    if (conversation[0]?.conversationId === message?.conversationId) {
      dispatch(assistantActions.removeLastMessage({ conversationId }))
      dispatch(deleteLastMessage(conversationId))
    }

    dispatch(
      assistantActions.followUpAsk({
        conversationId: conversationId,
        requestId: `${requestId}_followup_${conversation.length}`,
        question: query,
        timestamp,
        mode: 'simple',
      })
    )

    const request: WebSocketRequestWrapper<RequestAssistantAsk> = {
      requestId,
      timestamp,
      params: {
        message: query,
        mode: 'simple',
        regeneration: true,
        documents: selectedDocuments,
        conversationId: conversationId,
        ...getAskTools(settings.assistant.sources[sourceType]),
      },
    }
    executeQuery({
      event: DESIA_EVENT.CHAT_ASK,
      request,
    })

    setOpen(false)
  }

  useEffect(() => {
    setSelectedDocuments(filteredDocuments)
  }, [loading])

  useEffect(() => {
    setSelectedDocuments(filteredDocuments)
  }, [open])

  return (
    <Dialog open={open} onOpenChange={(v) => setOpen(v)}>
      <DialogTrigger asChild>
        <Button variant={'tertiary'} className="flex gap-2">
          {compact ? (
            <Stack className="h-6 w-6 !stroke-link" />
          ) : (
            <>
              <Stack className="h-6 w-6" />

              <span className="font-body-strong text-system-primary">
                View sources
              </span>
            </>
          )}
        </Button>
      </DialogTrigger>
      <DialogContent className="!w-[calc(100vw-48px)] tablet:!max-w-[750px] sm:!max-w-[750px] py-8 flex flex-col gap-10 top-6 mobile:top-20 translate-y-0">
        <DialogHeader>
          <DialogTitle>Sources</DialogTitle>
          <DialogDescription className="text-system-body font-body">
            View or select which sources to look through for the response
          </DialogDescription>
        </DialogHeader>
        <DialogBody>
          <div className="grid grid-cols-1">
            <Sources
              documents={filteredDocuments}
              showTabs={true}
              selectable={sourceType !== 'report' && canRetry}
              selectedDocuments={selectedDocuments}
              setSelectedDocuments={setSelectedDocuments}
            />
          </div>
        </DialogBody>
        {selectedDocuments.length !== filteredDocuments.length && canRetry && (
          <DialogFooter>
            <div className="flex flex-col gap-3 w-full">
              {showError && (
                <CustomAlert
                  variant="error"
                  title="You cannot retry with no sources selected"
                  description="Make sure at least one source is selected before continuing"
                />
              )}

              <div className="w-fit ml-auto">
                <Button disabled={isLoading}>
                  <div className="flex gap-2">
                    <RotateCcw className="size-6 shrink-0 stroke-[1.5px]" />

                    <TypographyBody
                      className="flex mobile:!hidden"
                      onClick={() => handleSubmit()}
                    >
                      Retry response
                    </TypographyBody>

                    <TypographyBody
                      className="hidden mobile:!flex"
                      onClick={() => handleSubmit()}
                    >
                      Retry response with selected sources
                    </TypographyBody>
                  </div>
                </Button>
              </div>
            </div>
          </DialogFooter>
        )}
      </DialogContent>
    </Dialog>
  )
}

export const PreviewSources = memo(
  ({
    documents,
    compact,
    loading,
    message,
    className,
    sourceType,
  }: Props) => {
    const [currentSourceMessageIndex, setCurrentSourceMessageIndex] =
      useState(0)
    const [repeatingSourceMessage, setRepeatingSourceMessage] = useState(false)
    const loadingSourceMessages = [
      'Connecting to sources.',
      'Connecting to sources..',
      'Connecting to sources...',
      'Connecting to sources.',
      'Connecting to sources..',
      'Connecting to sources...',
      'Extracting information.',
      'Extracting information..',
      'Extracting information...',
      'Extracting information.',
      'Extracting information..',
      'Extracting information...',
      'Analyzing the data.',
      'Analyzing the data..',
      'Analyzing the data...',
      'Analyzing the data.',
      'Analyzing the data..',
      'Analyzing the data...',
    ]

    const repeatingSourceMessages = [
      'Generating insights.',
      'Generating insights..',
      'Generating insights...',
    ]

    useEffect(() => {
      const intervalId = setInterval(() => {
        if (currentSourceMessageIndex >= loadingSourceMessages.length - 1) {
          setRepeatingSourceMessage(true)
          setCurrentSourceMessageIndex(
            (prevIndex) => (prevIndex + 1) % repeatingSourceMessages.length
          )
        } else {
          setCurrentSourceMessageIndex(
            (prevIndex) =>
              (prevIndex + 1) %
              (repeatingSourceMessage
                ? repeatingSourceMessages.length
                : loadingSourceMessages.length)
          )
        }
      }, 1000)

      return () => clearInterval(intervalId)
    }, [
      currentSourceMessageIndex,
      loadingSourceMessages.length,
      repeatingSourceMessages.length,
      repeatingSourceMessage,
    ])

    if (loading) {
      return (
        <div className={`flex gap-2 items-center`}>
          <div className={`transition-opacity ease-in-out duration-300`}>
            <img
              className="size-5 animate-logo-animation-fast"
              src="https://cdn.prod.website-files.com/66bccd21862b191477dc8806/66bce0be1491cc6deb168141_symbol.svg"
            />
          </div>
          <TypographyLabel className="text-system-body">
            {repeatingSourceMessage
              ? repeatingSourceMessages[currentSourceMessageIndex]
              : loadingSourceMessages[currentSourceMessageIndex]}
          </TypographyLabel>
        </div>
      )
    }

    if (documents.length === 0)
      return <div className="w-full m-h-40px my-2"></div>

    const nonChartDocuments = getGlobalUniqueDocuments(documents, false).filter(
      (v) =>
        !v.document_id.includes('chart') && !v.document_id.includes('table')
    )
    const webpages = nonChartDocuments.filter((d) =>
      d.document_id.includes('web')
    )

    const communicationDocuments = nonChartDocuments.filter(
      (d) =>
        d.document_id.startsWith('microsoft_outlook') ||
        d.document_id.startsWith('microsoft_teams')
    )
    const internalDocuments = nonChartDocuments.filter(
      (d) =>
        !d.document_id.includes('web') &&
        !d.document_id.startsWith('microsoft_outlook') &&
        !d.document_id.startsWith('microsoft_teams')
    )

    return (
      <div className={`$w-full ${className || ''}`}>
        <div className="flex flex-wrap gap-4 justify-between items-center">
          <div className="flex gap-6 items-center">
            {webpages.length > 0 && (
              <div className="flex gap-2 items-center">
                <span className="font-label text-system-body">Web</span>
                <span className="font-label-strong text-system-primary">
                  {webpages.length}
                </span>
                <div className="flex gap-1">
                  <WebIcons documents={nonChartDocuments} maxIcons={2} />
                </div>
              </div>
            )}
            {internalDocuments.length > 0 && (
              <div className="flex gap-2 items-center">
                <span className="font-label text-system-body">Library</span>
                <span className="font-label-strong text-system-primary">
                  {internalDocuments.length}
                </span>
                <div className="flex gap-1">
                  <LibraryIcons documents={nonChartDocuments} maxIcons={2} />
                </div>
              </div>
            )}
            {communicationDocuments.length > 0 && (
              <div className="flex gap-2 items-center">
                <span className="font-label text-system-body">
                  Communications
                </span>
                <span className="font-label-strong text-system-primary">
                  {communicationDocuments.length}
                </span>
                <div className="flex gap-1">
                  <CommunicationIcons
                    documents={nonChartDocuments}
                    maxIcons={2}
                  />
                </div>
              </div>
            )}
          </div>
          <ViewSources
            documents={nonChartDocuments}
            compact={compact}
            message={message}
            loading={loading}
            sourceType={sourceType}
          />
        </div>
      </div>
    )
  }
)
