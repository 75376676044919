import { ResponseChartData } from "@/types/types";
import Chart from "react-apexcharts";
import { ApexOptions } from "apexcharts";
import { ChartHeader } from "./ChartHeader";
import { useEffect, useMemo, useRef, useState } from "react";
import { formatChartNumber, formatTruncateDataLabel, isLowContrast, pxToCh} from "./ResponseChart";
import { Color } from "@/constants";

export const ResponseStackedBarChart = ({
  id,
  parsedData,
  series,
  options,
  compact,
}: {
  id: string;
  parsedData: ResponseChartData;
  series: ApexAxisChartSeries;
  options: ApexOptions;
  compact?: boolean;
}) => {
  const ref = useRef<HTMLDivElement>(null);
  const containerId = crypto.randomUUID();
  const barAmount = parsedData.data.length;
  const stackAmount = series.length
  const [maxChar, setMaxChar] = useState<number>(5);

  const fontSizeInPx = 14;

  const redrawSvg = () => {
    const parentElement = document.getElementById(`container-${containerId}`);
    const seriesElements = parentElement?.querySelectorAll(
      `.apexcharts-bar-series.apexcharts-plot-series .apexcharts-series`
    );
    const seriesDataLength =
      seriesElements?.[0]?.querySelectorAll(".apexcharts-bar-area").length || 0;

    for (let i = 0; i < seriesDataLength; i++) {
      const lastElements: Element[] = [];

      seriesElements?.forEach((serie) => {
        const bar = serie.querySelectorAll(".apexcharts-bar-area")[i];
        if (bar && bar.getAttribute("barHeight") !== "0") {
          lastElements.push(bar);
        }
      });

      const lastElement = lastElements[lastElements.length - 1];
      if (lastElement) {
        lastElement.setAttribute(
          "clip-path",
          "inset(0% 0% -11% 0% round 2px 2px 0 0)"
        );
      }
    }
  };

  const memoizedFormatter = useMemo(() => {
    return (val: string) => {
      return formatTruncateDataLabel({
        label: formatChartNumber(val) as string,
        maxChar: maxChar,
      });
    };
  }, [maxChar]);

  const barOptions: ApexOptions = {
    ...options,
    chart: {
      ...options.chart,
      type: "bar",
      stacked: true,
      events: {
        mounted: () => {
          redrawSvg();
        },
        updated: () => {
          redrawSvg();
        },
      },
    },
    xaxis: {
      ...options.xaxis,
    },
    yaxis: {
      show:false,
    },
    dataLabels: {
      enabled: stackAmount > 1,
      formatter: memoizedFormatter,
      style: {
        fontSize: `${fontSizeInPx}px`,
        fontFamily: "Inter, sans-serif",
        fontWeight: 350,
        colors: [
          function ({ seriesIndex }: { seriesIndex: number; w: any }) {
            return isLowContrast(seriesIndex)
                ? Color['system-secondary']
                : Color['system-primary'];
          },
        ],
      },
      textAnchor: "middle",
      offsetY: 2
    },
    plotOptions: {
      ...options.plotOptions,
      bar: {
        ...options.plotOptions?.bar,
        borderRadius:0,
        columnWidth: barAmount <= 5 ? "50%" : "80%",
        dataLabels: {
          ...options.plotOptions?.bar?.dataLabels,
          total: {
            formatter: (val: string) => {
              return formatTruncateDataLabel({
                label: formatChartNumber(val) as string,
                maxChar: maxChar,
                categoryAmount: barAmount
              });
            },
            enabled: true,
            style: {
              fontSize: "14px",
              fontFamily: "Inter, sans-serif",
              fontWeight: 400,
            },
            offsetY: -4,
          },
        },
      },
    },
  };

  useEffect(()=>{
    const parentElement = document.getElementById(`chart-container-${id}`)

    const barElements: NodeListOf<HTMLDivElement> = parentElement?.querySelectorAll(
      `.apexcharts-bar-area`,
    ) as NodeListOf<HTMLDivElement>;

    if(barElements && barElements.length > 0) {
      const barWidth = barElements[0].getAttribute("barWidth")
      if(barWidth){
        const barWidthInt = parseInt(barWidth)
        const calcMaxChar = pxToCh(barWidthInt, fontSizeInPx)
        setMaxChar(calcMaxChar)
      }
    }
  },[id])

  return (
    <div
      className={`flex flex-col gap-4 p-4 ${compact ? "mt-8" : "mt-10"} relative group`}
    >
      <ChartHeader chartRef={ref} parsedData={parsedData} compact={compact} />

      <div
        id={`container-${containerId}`}
        className="relative w-full h-[300px]"
      >
        <div
          className={`absolute max-w-full ${barAmount > 15 ? "w-fit" : "w-full"} laptop:max-w-[1200px] laptop:left-[50%] laptop:-translate-x-[50%] overflow-x-auto laptop:overflow-x-visible overflow-y-visible h-[300px]`}
        >
          <div
            ref={ref}
            id={`chart-container-${id}`}
            className={`${barAmount > 30 ? "w-[1200px]" : barAmount > 15 ? "w-[1000px]" : "w-full"} h-full [&_.apexcharts-tooltip]:!border-none [&_.apexcharts-tooltip]:!rounded-sm [&_.apexcharts-tooltip]:!bg-system-secondary`}
          >
            <Chart
              options={barOptions}
              series={series}
              type="bar"
              width="100%"
              height="100%"
            />
          </div>
        </div>
      </div>
    </div>
  );
};
