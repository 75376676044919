import { useEffect, useState } from 'react'
import {
  ASYNC_STATUS,
  IntegrationCode,
  UserIntegration,
} from '../../types/types'
import { getIntegrationName } from '@/utils/utils'
import { OptionDropdownMenuItem } from '../ui/option-dropdown-menu'
import { LibraryBig, MessageCircleMore, X } from 'lucide-react'
import { useDispatch, useSelector } from 'react-redux'
import { AppDispatch, RootState } from '@/store/store'
import { StateChip } from '../StateChip'
import { Checkbox } from '../ui/checkbox'
import { getIntegrationIcon } from '@/utils/components'
import { TypographyBody, TypographyLabel } from '../ui/Typography'
import { fetchUserIntegrations } from '../Integration/integrationThunk'
import { Button } from '../ui/button'
import { searchSlice } from './searchSlice'

export const SearchSourceSelector = () => {
  const connectors = useSelector((state: RootState) => state.search.connectors)

  const userIntegrations = useSelector(
    (state: RootState) => state.integration.userIntegrations
  )

  const dispatch = useDispatch<AppDispatch>()

  const [showCommunicationDropdown, setShowCommunicationDropdown] =
    useState(false)

  const personalIntegrations = userIntegrations.data?.filter(
    (v) => v.user_level_integration && v.integration_enabled_by_user
  )

  const isInternalSearchSelected = connectors.internalSearch
  const isCommunicationSelected =
    connectors.outlookSearch || connectors.teamsSearch
  const allCommunicationSelected =
    connectors.outlookSearch && connectors.teamsSearch

  const getIntegrationDescriptionText = (integration: UserIntegration) => {
    switch (integration.integration_code_name) {
      case IntegrationCode.OUTLOOK:
        return 'Emails including attachment files'
      case IntegrationCode.TEAMS:
        return 'Messages and call transcripts'
    }
  }

  const toggleCommunicationConnector = () => {
    const isTeamsEnabled = personalIntegrations?.some(
      (v) => v.integration_code_name === IntegrationCode.TEAMS
    )
    const isOutlookEnabled = personalIntegrations?.some(
      (v) => v.integration_code_name === IntegrationCode.OUTLOOK
    )
    dispatch(
      searchSlice.actions.setConnectors({
        ...connectors,
        teamsSearch: isTeamsEnabled ? !isCommunicationSelected : false,
        outlookSearch: isOutlookEnabled ? !isCommunicationSelected : false,
      })
    )
  }

  const toggleLibraryConnector = () => {
    dispatch(
      searchSlice.actions.setConnectors({
        ...connectors,
        internalSearch: !isInternalSearchSelected,
      })
    )
  }

  useEffect(() => {
    if (userIntegrations.status === ASYNC_STATUS.idle) {
      dispatch(fetchUserIntegrations())
    }
  }, [dispatch, userIntegrations.status])

  return (
    <>
      <div
        className="flex gap-6 items-center"
        onClick={(e) => e.stopPropagation()}
      >
        <div className="flex flex-wrap gap-3">
          <StateChip
            label={'Library'}
            active={isInternalSearchSelected}
            leftIcon={<LibraryBig className="size-5 shrink-0 stroke-[1.5px]" />}
            onClick={toggleLibraryConnector}
          />

          {(personalIntegrations?.length || 0) > 0 && (
            <StateChip
              label={
                isCommunicationSelected && !allCommunicationSelected
                  ? '1 source'
                  : 'Communications'
              }
              active={isCommunicationSelected}
              leftIcon={
                <MessageCircleMore className="size-5 shrink-0 stroke-[1.5px]" />
              }
              onClick={toggleCommunicationConnector}
              openDropdown={showCommunicationDropdown}
              onDropdownOpen={(v) => setShowCommunicationDropdown(v)}
              dropdownContent={
                <>
                  <div
                    className="relative"
                    onClick={(e) => e.stopPropagation()}
                  >
                    <div className="flex flex-col gap-0 py-1">
                      {personalIntegrations?.map((integration, index) => {
                        return (
                          <OptionDropdownMenuItem
                            selectable={false}
                            key={`integration-dropdown-item-${index}`}
                            className="[&>div]:hover:!bg-transparent [&>div]:hover:!border-transparent"
                          >
                            <div className="flex gap-2 py-2 items-start px-2 min-w-[280px]">
                              <Checkbox
                                onClick={(e) => e.stopPropagation()}
                                checked={
                                  integration.integration_code_name ===
                                  IntegrationCode.TEAMS
                                    ? connectors.teamsSearch
                                    : connectors.outlookSearch
                                }
                                onCheckedChange={() => {
                                  if (
                                    integration.integration_code_name ===
                                    IntegrationCode.TEAMS
                                  ) {
                                    dispatch(
                                      searchSlice.actions.setConnectors({
                                        ...connectors,
                                        teamsSearch: !connectors.teamsSearch,
                                      })
                                    )
                                  } else if (
                                    integration.integration_code_name ===
                                    IntegrationCode.OUTLOOK
                                  ) {
                                    dispatch(
                                      searchSlice.actions.setConnectors({
                                        ...connectors,
                                        outlookSearch:
                                          !connectors.outlookSearch,
                                      })
                                    )
                                  }
                                }}
                              />

                              <div className="flex flex-col gap-1 items-center">
                                <div className="flex gap-2 items-center w-full">
                                  <div className="flex items-center justify-center size-6 shrink-0 border-[1.5px] border-system-border-light rounded-sm">
                                    {getIntegrationIcon(
                                      integration.integration_code_name,
                                      true,
                                      'w-4 shrink-0'
                                    )}
                                  </div>

                                  <TypographyBody>
                                    {getIntegrationName(
                                      integration.integration_code_name
                                    )}
                                  </TypographyBody>
                                </div>

                                <TypographyLabel className="text-system-body">
                                  {getIntegrationDescriptionText(integration)}
                                </TypographyLabel>
                              </div>
                            </div>
                          </OptionDropdownMenuItem>
                        )
                      })}
                    </div>
                    <div className="absolute right-0 top-1">
                      <Button
                        variant="tertiary"
                        onClick={() => setShowCommunicationDropdown(false)}
                      >
                        <X className="size-6 shrink-0 stroke-[1.5px]" />
                      </Button>
                    </div>
                  </div>
                </>
              }
            />
          )}
        </div>
      </div>
    </>
  )
}
